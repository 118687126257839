// components/TestimonialsSection.jsx
import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';

const TestimonialsSection = () => {
  const sliderRef = useRef();

  useEffect(() => {
    gsap.fromTo(
      sliderRef.current.children,
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, stagger: 0.2, duration: 1, ease: 'power3.out' }
    );
  }, []);

  const testimonials = [
    { name: "Julienne Gounou", business: "Etuveuse de riz", video: "/videos/julienne.mp4" },
    { name: "Aminatou Tchibozo", business: "Productrice de jus", video: "/videos/aminatou.mp4" },
    { name: "Youssouf Karim", business: "Fabricant de meubles", video: "/videos/youssouf.mp4" }
  ];

  return (
    <section className="py-16 px-8 lg:px-20">
      <h2 className="text-4xl font-semibold text-center mb-10">Témoignages d'Entrepreneurs</h2>
      <div ref={sliderRef} className="flex flex-wrap justify-center gap-10">
        {testimonials.map((t, index) => (
          <div key={index} className="w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center">
            {/* Video Container */}
            <video
              src={t.video}
              className="w-full h-auto rounded-lg shadow-md transition-transform duration-300 hover:scale-105"
              controls // Show video controls
              poster="/path-to-thumbnail.jpg" // Poster image to show before play
              aria-label={`Témoignage de ${t.name}`} // Accessibility
            />
            {/* Video Metadata */}
            <div className="mt-4 text-center">
              <h4 className="text-xl font-semibold text-gray-800">{t.name}</h4>
              <p className="text-gray-600 text-sm">{t.business}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TestimonialsSection;
