import React, {useEffect} from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
} from "@material-tailwind/react";
import Evenement from './Evenement';
import Communique from './Communique'
import Projet from './Projet';

function TabsComponentActualite(props) {
    const { id } = useParams()
    const [activeTab, setActiveTab] = React.useState(id || "event");
    const nav = useNavigate()

    const handleTabClick = (value) => {
        setActiveTab(value);
        
        nav(`./${value}`);
    };

    useEffect(() => {
        nav(`/actualites`);
    }, [id]);
    
    const data = [
        {
            label: "ÉVENEMENTS",
            value: "event",
            desc: <Evenement />,
        },
        {
            label: "COMMUNIQUÉS",
            value: "comunique",
            desc: <Communique />,
        },
        {
            label: "PROJETS",
            value: "projet",
            desc: <Projet />,
        },
    ];
    return (
        <>
            <div className="border-b border-blue-gray-50 font-[' Montserrat']">
                <Tabs value={activeTab}>
                    <TabsHeader
                        className="rounded-none bg-white w-full lg:pr-72 lg:pl-32"
                        indicatorProps={{
                            className:
                                "bg-transparent border-b-2 border-yellow-300 shadow-none rounded-none p-7 w-full",
                        }}
                    >
                        {data.map(({ label, value }) => (
                            <Tab
                                key={value}
                                value={value}
                                onClick={() => handleTabClick(value)}
                                className={activeTab === value ? "text-gray-900 font-semibold p-7 w-[20%] max-md:w-[30%]" : "p-7 w-[20%] max-md:w-[30%] font-semibold"}
                            >
                                <span className='max-md:text-[12px]'>{label}</span>
                            </Tab>
                        ))}
                    </TabsHeader>
                    <TabsBody className='lg:pr-72 lg:pl-32'>
                        {data.map(({ value, desc }) => (
                            <TabPanel key={value} value={value}>
                                {desc}
                            </TabPanel>
                        ))}
                    </TabsBody>
                </Tabs>
            </div>
        </>
    );
}

export default TabsComponentActualite;