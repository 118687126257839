import React, {useEffect, useRef} from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import fondvert from '../Images/fondlogovert.jpg'

gsap.registerPlugin(ScrollTrigger);

function Ambitions(props) {
    const missionItemsRef = useRef([]);

    useEffect(() => {
        const items = missionItemsRef.current;
        
        gsap.fromTo(items, 
            { opacity: 0, y: 50 }, 
            { 
                opacity: 1, 
                y: 0, 
                duration: 0.6, 
                ease: "power2.out", 
                stagger: 0.2,
                scrollTrigger: {
                    trigger: items,
                    start: "top 80%",
                    end: "bottom 20%",
                    toggleActions: "play none none none",
                }
            }
        );
    }, []);

    return (
        <>
            <section style={{ backgroundImage: `url(${fondvert})` }} className="bg-white bg-no-repeat dark:bg-gray-900 max-md:px-4 p-28">
                <div className='grid grid-cols-2 max-xl:flex max-xl:flex-col'>
                    <div className='flex text-left'>
                        <div>
                            <h2 className="text-neutral-900 max-md:text-[25px] text-[32px] font-semibold font-['Montserrat']">Quelles sont nos ambitions ?</h2>
                        </div>
                    </div>
                    <div>
                        <div className="text-[24px] max-md:text-[17px] font-[' Montserrat']">
                            <p className="mb-4 font-['Montserrat']">
                                L'ADPME ambitionne de :
                            </p>
                            <p className="mb-4 text-[17px] text-justify">
                                <div className="grid grid-cols-2 gap-4 mt-8 max-xl:flex max-xl:flex-col">
                                    <div>
                                        <ul className="font-['Montserrat'] text-black list-inside dark:text-black max-xl:space-y-6">
                                            <li ref={(el) => missionItemsRef.current[0] = el} className="flex items-center mb-5">
                                                <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                                </svg>
                                                Contribuer à l’émergence d’une masse critique de petites et moyennes entreprises transformatrices des matières premières locales ;
                                            </li>
                                            <li ref={(el) => missionItemsRef.current[1] = el} className="font-['Montserrat'] flex items-center mb-5">
                                                <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                                </svg>
                                                Accélérer l’essor d’un écosystème béninois d’entreprises technologiques et de start-up innovantes ;
                                            </li>
                                            <li ref={(el) => missionItemsRef.current[2] = el} className="font-['Montserrat'] flex items-center mb-5">
                                                <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                                </svg>
                                                Garantir l’égalité d’accès aux offres de soutien et de développement de l’entrepreneuriat dans toutes les Communes du Bénin notamment celles qui sont les plus fragiles ;
                                            </li>
                                            <li ref={(el) => missionItemsRef.current[3] = el} className="font-['Montserrat'] flex items-center mb-5">
                                                <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                                </svg>
                                                Contribuer à la mise à niveau et au redressement des entreprises en difficulté ;
                                            </li>
                                        </ul>
                                    </div>


                                    <div>
                                        <ul className='max-xl:space-y-6'>
                                            <li ref={(el) => missionItemsRef.current[4] = el} className="font-['Montserrat'] flex items-center mb-5">
                                                <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                                </svg>
                                                Apporter un soutien décisif aux secteurs stratégiques ou prioritaires (tourisme, artisanat, agroalimentaire, numérique, etc.) ainsi qu’aux entreprises à forte croissance ;
                                            </li>
                                            <li ref={(el) => missionItemsRef.current[5] = el} className="font-['Montserrat'] flex items-center mb-5">
                                                <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                                </svg>
                                                Faire émerger des champions nationaux capables de contribuer au renforcement de la résilience de l’économie béninoise ;
                                            </li>
                                            <li ref={(el) => missionItemsRef.current[6] = el} className="font-['Montserrat'] flex items-center mb-5">
                                                <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                                </svg>
                                                Accroître la mobilisation des capitaux et favoriser le changement d’échelle du financement des micros, petites et moyennes entreprises béninoises.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </p>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Ambitions;