
import './App.css';
import React, {useEffect} from 'react'
import Header from './default_pages/header/header';
import Footer from './default_pages/footer/footer';
import { Outlet } from 'react-router-dom';
import Newsletter from './default_pages/newsletter/Newsletter';
import CacheBuster from 'react-cache-buster';
import packageFile from '../package.json';
import Loading from './Loading';
import ScrollListener from './ScrollListener';

function App() {
  const { version } = packageFile
  const isProduction = process.env.NODE_ENV === 'production';

  return (
    <>
      <CacheBuster
        currentVersion={version}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
      >
        {/* <ScrollListener> */}
          <Header />
        {/* </ScrollListener> */}
        <Outlet />
        <Footer />
      </CacheBuster>
      
    </>
  );
}

export default App;
