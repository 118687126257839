import React from 'react';

function Vision(props) {
    return (
        <>
            <section className="bg-[#E3F2E5] dark:bg-gray-900 max-md:px-4 p-28">
                <div className='grid grid-cols-2 max-xl:flex max-xl:flex-col'>
                    <div className='flex text-left'>
                        <div>
                            <h2 className="text-neutral-900 max-md:text-[25px] text-[32px] font-semibold font-['Montserrat']">Que visons-nous ?</h2>
                        </div>
                    </div>
                    <div>
                        <div className="text-[24px] max-md:text-[17px] font-[' Montserrat']">
                            <p className="text-justify font-['Montserrat']">
                                A l’horizon 2030, les micros, petites et moyennes entreprises béninoises sont compétitives, émergentes et impulsent la transformation structurelle de l’économie, offrant une prospérité durable dans tout le Bénin.
                            </p>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Vision;