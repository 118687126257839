import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';
import homepage from '../Images/homme-afro-americain-peau-foncee-positive-rit-comme-entend-quelque-chose-drole.png';
import bgaccueil from '../Images/header_accueil.jpg';

gsap.registerPlugin(TextPlugin);

function Banniere() {
  const textRef1 = useRef(null);
  const textRef2 = useRef(null);
  const imgRef = useRef(null);

  useEffect(() => {
    // Animation des textes
    const tlText = gsap.timeline({
      repeat: -1, // Répétition infinie
      yoyo: true, // Animation dans les deux sens
      defaults: { ease: "none" }
    });

    tlText.to(textRef1.current, {
      duration: 4,
      text: "devient plus simple",
      color: "green",
      className: 'text-green-700',
    })
    .to(textRef2.current, {
      duration: 4,
      text: "quand on est bien guidé !",
      color: "green",
      className: 'text-green-700',
    }, "+=0");

    // Animation de l'image (pulsation avec flou très léger)
    const tlImage = gsap.timeline({
      repeat: -1, // Répétition infinie
      yoyo: true, // Animation dans les deux sens
      defaults: { ease: "power1.inOut" }
    });

    tlImage.to(imgRef.current, {
      duration: 2,
      scale: 1.05, // Augmente légèrement la taille
      filter: "blur(1px)", // Applique un flou très léger
      ease: "power1.inOut",
      y: "-5px" // Déplace légèrement l'image vers le haut pour éviter le dépassement
    })
    .to(imgRef.current, {
      duration: 2,
      scale: 1, // Rétablit la taille
      filter: "blur(0px)", // Rétablit le flou
      ease: "power1.inOut",
      y: "0px" // Rétablit la position d'origine
    });

  }, []);

  return (
    <>
      <div style={{ backgroundImage: `url(${bgaccueil})` }} className='overflow-hidden w-full bg-no-repeat bg-cover justify-items-center pt-9'>
        <div className='flex flex-row max-md:text-center max-md:flex max-md:flex-col max-md:px-4 justify-center items-center'>
          <div>
            <div className="text-neutral-800 max-md:mb-5 max-md:text-[24px] text-[56px] font-semibold font-['Montserrat']">Entreprendre au Bénin,</div>
            <div className="text-neutral-800 max-md:mb-5 max-md:text-[24px] text-[56px] font-semibold font-['Montserrat']">
              <span ref={textRef1}></span>
            </div>
            <div className="text-neutral-800 max-md:mb-5 max-md:text-[24px] text-[56px] font-semibold font-['Montserrat']">
              <span ref={textRef2}></span>
            </div>
            <div className="text-neutral-800 max-md:text-xl text-2xl font-normal font-['Montserrat']">
              Le guichet unique de promotion des Petites et Moyennes<br />Entreprises et des Petites et Moyennes Industries au Bénin.
            </div>
          </div>
          <div className='relative w-[521px] max-md:w-[521px] max-md:h-[350px] h-[550px]'>
            <img
              ref={imgRef}
              className='absolute inset-0 w-full h-full object-cover'
              src={homepage}
              alt="Homme afro-américain souriant"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Banniere;
