// components/MethodologySection.jsx
import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import imgMetho from "../Images/imgMetho.jpg";
import {
  FaChalkboardTeacher,
  FaHandsHelping,
  FaPeopleArrows,
} from "react-icons/fa";

gsap.registerPlugin(ScrollTrigger);

const MethodologySection = () => {
  const contentRef = useRef([]);

  useEffect(() => {
    contentRef.current.forEach((item, i) => {
      gsap.fromTo(
        item,
        { opacity: 0, x: -20 },
        {
          opacity: 1,
          x: 0,
          duration: 1,
          delay: i * 0.2,
          ease: "power3.out",
          scrollTrigger: { trigger: item, start: "top 85%" },
        }
      );
    });
  }, []);

  const methodologyItems = [
    {
      title: "Formation",
      description:
        "Ateliers immersifs pour renforcer les compétences en gestion.",
      icon: <FaChalkboardTeacher className="text-blue-500 text-5xl" />,
    },
    {
      title: "Coaching",
      description:
        "Accompagnement personnalisé pour transformer les compétences en actions concrètes.",
      icon: <FaHandsHelping className="text-blue-500 text-5xl" />,
    },
    {
      title: "Collaboration",
      description: "Partenariats pour un soutien durable au sein des PME.",
      icon: <FaPeopleArrows className="text-blue-500 text-5xl" />,
    },
  ];

  return (
    <section className="flex flex-col lg:flex-row py-32 bg-gray-50 px-10 lg:px-28">
      {/* Image agrandie à gauche */}
      <div
        className="lg:w-3/5 h-96 lg:h-auto rounded-lg overflow-hidden shadow-lg relative bg-cover bg-center mb-10 lg:mb-0"
        style={{ backgroundImage: `url(${imgMetho})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-r from-gray-900 to-transparent opacity-70"></div>
      </div>

      {/* Contenu étendu à droite */}
      <div className="lg:w-2/5 space-y-10 lg:pl-16">
        <h2 className="text-4xl font-bold text-gray-900 mb-10">
          Notre Approche
        </h2>
        <div className="space-y-10">
          {methodologyItems.map((item, index) => (
            <div
              key={index}
              ref={(el) => (contentRef.current[index] = el)}
              className="flex items-start space-x-4"
            >
              {/* Icône avec couleur douce et taille réduite */}
              <div
                className={`p-3 rounded-full text-white shadow-md ${index % 3 === 0 ? "bg-red-200" : index % 3 === 1 ? "bg-green-200" : "bg-yellow-200"}`}
              >
                <div className="w-6 h-6 text-opacity-80">{item.icon}</div>
              </div>
              <div>
                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                  {item.title}
                </h3>
                <p className="text-gray-700">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MethodologySection;

