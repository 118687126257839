import React,{useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
// import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import InfoPerso from './Formulaires/InfoPerso';
import { FileInput, Label, Modal, TextInput, Select, Button, Textarea } from 'flowbite-react';
import MenuItem from '@mui/material/MenuItem';
import SelectM from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios'
import img from '../Images/Plan-de-travail-3.jpg'
import img2 from '../Images/Plan-de-travail-4.jpg'
import img3 from '../Images/Plan-de-travail-5.jpg'

const steps = [
    {
        label: 'Informations personnelles',
        description: <InfoPerso />,
    },
    {
        label: 'Identité du projet',
        description:
            'An ad group contains one or more ads which target a shared set of keywords.',
    },
    {
        label: 'Synthèse de votre idée d\'entreprise',
        description: `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`,
    },
];

export default function Description() {
    const currentDate = new Date();
    const [activeStep, setActiveStep] = useState(0);
    const [nom, setNom] = useState('')
    const [prenom, setPrenom] = useState('')
    const [nvEtude, setNvEtude] = useState('')
    const [expPro, setExpPro] = useState('')
    const [expYear, setExpYear] = useState(0)
    const [opp, setOpp] = useState('')
    const [offer, setOffer] = useState('')
    const [market, setMarket] = useState('')
    const [yourExp, setYourExp] = useState('')
    const [caract, setCaract] = useState('')
    const [yourAttent, setYourAttent] = useState('')
    const [old, setOld] = useState(new Date())
    const [genre, setGenre] = useState('')
    const [mail, setMail] = useState('')
    const [tel, setTel] = useState('')
    const [otherTel, setOtherTel] = useState('')
    const [fonction, setFonction] = useState('')
    const [part, setPart] = useState(0)
    const [ideas, setIdeas] = useState('')
    const [raisonSociale, setRaisonSociale] = useState('')
    const [secteur, setSecteur] = useState(0)
    const [sSecteur, setSSecteur] = useState([])
    const [sousSecteur, setSousSecteur] = useState(0)
    const [numeroRCCM, setNumeroRCCM] = useState('')
    const [villageOuQuartier, setVillageOuQuartier] = useState(0)
    const [arrondissement, setArrondissement] = useState(0)
    const [commune, setCommune] = useState(0)
    const [dept, setDept] = useState(0)
    const [dateCreation, setDateCreation] = useState(new Date())
    const [adresse, setAdresse] = useState('')
    const [telEntreprise, setTelEntreprise] = useState('')
    const [mailEntreprise, setMailEntreprise] = useState('')
    const [bp, setBp] = useState('')
    const [statutJuridique, setStatutJuridique] = useState(0)
    const [capitaleSocial, setCapitalSocial] = useState(0)
    const [currentYear, setCurrentYear] = useState(0);
    const [firstYear, setFirstYear] = useState(currentDate.getFullYear()-1);
    const [firstYearCA, setFirstYearCA] = useState(0);
    const [firstYearEmployer, setFirstYearEmployer] = useState(0);
    const [secondYear, setSecondYear] = useState(currentDate.getFullYear()-2);
    const [secondYearCA, setSecondYearCA] = useState(0);
    const [secondYearEmployer, setSecondYearEmployer] = useState(0);
    const [value, setValue] = React.useState('1');
    const [communes, setCommunes] = useState([]);
    const [communesChoose, setCommunesChoose] = useState([]);
    const [arrondissements, setArrondissements] = useState([]);
    const [arrondissementsChoose, setArrondissementsChoose] = useState([]);
    const [depts, setDepts] = useState([]);
    const [vils, setVils] = useState([]);
    const [secteurs, setSecteurs] = useState([]);
    const [sousSecteurs, setSousSecteurs] = useState([]);
    const [sousSecteursChoose, setSousSecteursChoose] = useState([]);
    const [vilsChoose, setVilsChoose] = useState([]);
    const [projets, setProjets] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFile2, setSelectedFile2] = useState(null);
    const [statusJs, setStatutJs] = useState([]);
    const [projet, setProjet] = useState([]);
    const [searchComapnyName, setSearchCompanyName] = useState('');
    const [companySearched, setCompanySearched] = useState([]);
    const [companySearchedId, setCompanySearchedId] = useState(0);

    const handleRadioChange = (event) => {
        setGenre(event.target.value);
    };

    const deptChoose = (e) => {
        setDept(e.target.value)
        setCommunesChoose(communes.filter((com) => com.departementId == e.target.value))
        console.log("departementid :" + e.target.value)
        console.log(communesChoose)
    }

    const comChoose = (e) => {
        setCommune(e.target.value)
        setArrondissementsChoose(arrondissements.filter((com) => com.communeId == e.target.value))
        console.log("communeid :" + e.target.value)
        console.log(arrondissementsChoose)
    }

    const arrChoose = (e) => {
        setArrondissement(e.target.value)
        setVilsChoose(vils.filter((com) => com.arrondissementId == e.target.value))
        console.log("Arrondissementid :" + e.target.value)
        console.log(vilsChoose)
    }

    const sectChoose = (e) => {
        setSecteur(e.target.value)
        setSousSecteursChoose(sousSecteurs.filter((com) => com.secteurId == e.target.value))
        console.log("secteurId :" + e.target.value)
        console.log(vilsChoose)
    }

    const handleChangeSSecteur = (event) => {
        const {
            target: { value },
        } = event;
        setSSecteur(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeProjet = (event) => {
        const {
            target: { value },
        } = event;
        setProjet(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const handleNext = () => {
        setActiveStep((prevActiveStep) => {
            console.log(prevActiveStep)
            return prevActiveStep + 1
        });
    };
    const handleSubmit = () => {
        let tab = []
        sSecteur.map((el) => {
            let sectChoose = []
            sectChoose = sousSecteursChoose.find((s) => s.libelle == el)
            tab.push({ "id": sectChoose.id })
        })
        let tabp = []
        projet.map((el) => {
            let sectChoose = []
            console.log(el)
            sectChoose = projets.find((s) => s.actualite.title == el)
            // sectChoose = projets.find((s) => s.libelle == el)
            tabp.push({ "appelAProjetId": sectChoose.id, "decision": true })
            console.log(tabp)
        })
        console.log(
            old+ "\n "+
            genre+ "\n "+
            mail+ "\n "+
            tel+ "\n "+
            fonction+ "\n "+
            part+ "\n "+
            ideas+ "\n "+
            raisonSociale+ "\n "+
            secteur+ "\n "+
            sousSecteur+ "\n "+
            numeroRCCM+ "\n "+
            villageOuQuartier+ "\n "+
            arrondissement+ "\n "+
            commune+ "\n "+
            dept+ "\n "+
            dateCreation+ "\n "+
            adresse+ "\n "+
            telEntreprise+ "\n "+
            mailEntreprise+ "\n "+
            bp+ "\n "+
            statutJuridique+ "\n "+
            capitaleSocial+ "\n "+
            firstYear+ "\n "+
            firstYearCA+ "\n "+
            firstYearEmployer+ "\n "+
            secondYear+ "\n "+
            secondYearCA+ "\n "+
            secondYearEmployer + "\n " +
            tab
        )
        if (window.confirm("Voulez-vous vraiment éffectuer cet enregistrement ?") == true) {
            const newurl = process.env.REACT_APP_URL_STANDART + "api/twoHundredAndFiftyYoungs"
            axios.post(newurl, {
                "sigle": ideas,
                "raisonSociale": raisonSociale,
                "rccm": numeroRCCM,
                "adresse": adresse,
                "phone": telEntreprise,
                "email": mailEntreprise,
                "capitalSocial": capitaleSocial,
                "statusJuridiqueId": statutJuridique,
                "villagesOuQuartiersDevilleId": villageOuQuartier,
                "website": "website.rvr",
                "boitePostale": bp,
                "Exercice": [
                    {
                        "ca": parseInt(firstYearCA,10),
                        "nbreEmp": parseInt(firstYearEmployer,10),
                        "annee": firstYear
                    },
                    {
                        "ca": parseInt(secondYearCA,10),
                        "nbreEmp": parseInt(secondYearEmployer,10),
                        "annee": secondYear
                    }
                ],
                "CreateurDirigeant": [
                    {
                        "gender": genre,
                        "phone": tel,
                        "birthday": old,
                        "email": mail,
                        "poste": fonction,
                        "part": parseInt(part,10)
                    }
                ],
                "ParticipeAppelAProjet": tabp,
                "sousSecteurs":tab
            }, { withCredentials: true })
                .then(response => {
                    alert('Votre entreprise a été enregistrée.')
                    window.location.reload()
                })
                .catch(err => {
                    alert('Erreur lors de la suppression du recrutement')
                })
        }
    };

    const SearchCompany = async(e)=>{
        e.preventDefault();
        setOld('')
        setMail('')
        setTel('')
        setFonction(0)
        setPart(0)
        const newurlsearch = process.env.REACT_APP_URL_STANDART + `api/pmes/${searchComapnyName}`
        await axios.get(newurlsearch, { withCredentials: true })
            .then(response => {
                //alert('Recrutement supprimer')
                setCompanySearched(response.data.data);
                const tab = response.data.data[0]
                setCompanySearchedId(tab.id)
            })
            .catch(err => {
                alert('Aucune entreprise n\'a été trouvée. Assurez vous d\'avoir bien renseigné le nom ou la raison sociale')
            })
    }

    const EditForm = (e)=>{
        e.preventDefault();
        const newurlsearch = process.env.REACT_APP_URL_STANDART + `api/pmes/${companySearchedId}`
        axios.put(newurlsearch, {
            "CreateurDirigeant": [
                {
                    "gender": genre,
                    "phone": tel,
                    "birthday": old,
                    "email": mail,
                    "poste": fonction,
                    "part": parseInt(part, 10)
                }
            ],
        } , { withCredentials: true })
            .then(response => {
                alert('Vous avez été enregistré')
                window.location.reload()
            })
            .catch(err => {
                alert('Erreur lors de la soumission du formulaire')
            })
    }

    const handleChangeActivity = (event) => {
        const secteurId = event.target.value;
        const isChecked = event.target.checked;

        // Utiliser une logique pour ajouter ou supprimer l'ID du secteur
        // en fonction de l'état de la case à cocher
        setSecteur((prevSecteurs) => {
            if (isChecked) {
                // Ajouter l'ID du secteur à la liste
                return [...prevSecteurs, secteurId];
            } else {
                // Supprimer l'ID du secteur de la liste
                return prevSecteurs.filter((id) => id !== secteurId);
            }
        });
        console.log(secteur)
    };

    // const handleBack = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // };

    // const handleReset = () => {
    //     setActiveStep(0);
    // };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    }
    const handleFileChange2 = (event) => {
        setSelectedFile2(event.target.files[0]);
    }
    useEffect(() => {
        const getCurrentYear = () => {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            setCurrentYear(year);
        };

        getCurrentYear();
        const newurlcom = process.env.REACT_APP_URL_STANDART + "api/communes"
        axios.get(newurlcom, {}, { withCredentials: true })
            .then(response => {
                //alert('Recrutement supprimer')
                setCommunes(response.data.data)
            })
            .catch(err => {
                alert('Erreur lors de la suppression des communes')
            })
        const newurlarr = process.env.REACT_APP_URL_STANDART + "api/arrondissements"
        axios.get(newurlarr, {}, { withCredentials: true })
            .then(response => {
                //alert('Recrutement supprimer')
                setArrondissements(response.data.data)
            })
            .catch(err => {
                alert('Erreur lors de la suppression des arrondissements')
            })
        const newurldept = process.env.REACT_APP_URL_STANDART + "api/departements"
        axios.get(newurldept, {}, { withCredentials: true })
            .then(response => {
                //alert('Recrutement supprimer')
                setDepts(response.data.data)
            })
            .catch(err => {
                alert('Erreur lors de la suppression du départements')
            })
        const newurlvils = process.env.REACT_APP_URL_STANDART + "api/villagesOuQuartiersDeVilles"
        axios.get(newurlvils, {}, { withCredentials: true })
            .then(response => {
                //alert('Recrutement supprimer')
                setVils(response.data.data)
            })
            .catch(err => {
                alert('Erreur lors de la suppression du villages ou quartiers')
            })
        const newurlsects = process.env.REACT_APP_URL_STANDART + "api/secteurs"
        axios.get(newurlsects, {}, { withCredentials: true })
            .then(response => {
                //alert('Recrutement supprimer')
                setSecteurs(response.data.data)
            })
            .catch(err => {
                alert('Erreur lors de la suppression du secteurs')
            })
        const newurlssects = process.env.REACT_APP_URL_STANDART + "api/sousSecteurs"
        axios.get(newurlssects, {}, { withCredentials: true })
            .then(response => {
                //alert('Recrutement supprimer')
                setSousSecteurs(response.data.data)
            })
            .catch(err => {
                alert('Erreur lors de la suppression du secteurs')
            })
        const newurlpro = process.env.REACT_APP_URL_STANDART + "api/appelAProjets"
        axios.get(newurlpro, {}, { withCredentials: true })
            .then(response => {
                //alert('Recrutement supprimer')
                setProjets(response.data.data.appelAProjets)
            })
            .catch(err => {
                alert('Erreur lors de la suppression du secteurs')
            })
        const newurlstat = process.env.REACT_APP_URL_STANDART + "api/statutJuridiques"
        axios.get(newurlstat, {}, { withCredentials: true })
            .then(response => {
                //alert('Recrutement supprimer')
                setStatutJs(response.data.data)
            })
            .catch(err => {
                alert('Erreur lors de la suppression du statut juridique')
            })
    }, []);

    return (
        <div className='max-xl:px-4 px-14 mx-auto max-w-screen-xl text-left py-24 lg:py-15 sm:py-15'>
            
            {/* <div className='mb-10'>
                <span className='text-4xl font-bold'>Candidatez de façon spontanée</span><br />
                <span className='text-2xl'>En remplissant le formulaire suivant :</span>
            </div> */}
            <form>
                <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Information personnelle</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Utilisez une adresse permanente où vous pouvez recevoir du courrier.</p>

                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Prénom(s)
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="first-name"
                                        id="first-name"
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setPrenom(e.target.value)} value={prenom}

                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Nom
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="last-name"
                                        id="last-name"
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setNom(e.target.value)} value={nom}

                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    Adresse mail
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                    />
                                </div>

                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="naissance" className="block text-sm font-medium leading-6 text-gray-900">
                                    Date de naissance
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="naissance"
                                        name="naissance"
                                        type="date"
                                        autoComplete="naissance"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                    />
                                </div>

                            </div>
                            <div className="sm:col-span-6">
                                <label htmlFor="naissance" className="block text-sm font-medium leading-6 text-gray-900">
                                    Genre
                                </label>
                                <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                                    <input onChange={handleRadioChange} id="bordered-radio-1" type="radio" value="HOMME" name="bordered-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label for="bordered-radio-1" className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Homme</label>
                                </div>
                                <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                                    <input onChange={handleRadioChange} id="bordered-radio-2" type="radio" value="FEMME" name="bordered-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label for="bordered-radio-2" className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Femme</label>
                                </div>
                            </div>
                            
                            <div className="sm:col-span-3">
                                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                    Département
                                </label>
                                <div className="mt-2">
                                    <select
                                        id="country"
                                        name="country"
                                        autoComplete="country-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={deptChoose} 
                                        value={dept}
                                    >
                                        <option disabed selected>Sélectionner un département</option>
                                        {depts.length > 0 ?
                                            depts.map((sp, index) => {
                                                return (
                                                    <option key={index} value={sp.id}>{sp.libelle}</option>
                                                )
                                            }) :
                                            ("Aucun départements")}
                                    </select>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                    Commune
                                </label>
                                <div className="mt-2">
                                    <select
                                        id="country"
                                        name="country"
                                        autoComplete="country-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        disabled={dept ? false : true}
                                        onChange={comChoose} value={commune}
                                    >
                                        <option disabed selected>Sélectionner une commune</option>
                                        {communesChoose.length > 0 ?
                                            communesChoose.map((sp, index) => {
                                                return (
                                                    <option key={index} value={sp.id}>{sp.libelle}</option>
                                                )
                                            }) :
                                            ("Aucune commune")}
                                    </select>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                    Arrondissement
                                </label>
                                <div className="mt-2">
                                    <select
                                        id="country"
                                        name="country"
                                        autoComplete="country-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        disabled={commune ? false : true}
                                        onChange={arrChoose} value={arrondissement}
                                    >
                                        <option disabed selected>Sélectionner un arrondissement</option>
                                        {arrondissementsChoose.length > 0 ?
                                            arrondissementsChoose.map((sp, index) => {
                                                return (
                                                    <option key={index} value={sp.id}>{sp.libelle}</option>
                                                )
                                            }) :
                                            ("Aucun arrondissement")}
                                    </select>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                    Village ou quartier de ville
                                </label>
                                <div className="mt-2">
                                    <select
                                        id="country"
                                        name="country"
                                        autoComplete="country-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        disabled={arrondissement ? false : true}
                                        onChange={(e) => setVillageOuQuartier(e.target.value)} value={villageOuQuartier}
                                    >
                                        <option disabed selected>Sélectionner votre village ou votre quartier</option>
                                        {vilsChoose.length > 0 ?
                                            vilsChoose.map((sp, index) => {
                                                return (
                                                    <option key={index} value={sp.id}>{sp.libelle}</option>
                                                )
                                            }) :
                                            ("Aucun Village ou quartier")}
                                    </select>
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="adresse" className="block text-sm font-medium leading-6 text-gray-900">
                                    Adresse physique de l'entreprise (Carré, Lot, ect...)
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="adresse"
                                        name="adresse"
                                        type="text"
                                        autoComplete="adresse"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        placeholder='Ex: c/20XX Lot X'

                                    />
                                </div>

                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="anneeexp" className="block text-sm font-medium leading-6 text-gray-900">
                                    Année d'expérience du projet
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="anneeexp"
                                        name="anneeexp"
                                        type="number"
                                        autoComplete="anneeexp"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        placeholder='Renseigner ici votre expérience professionnelle sur les 3 dernières années...'

                                    />
                                </div>

                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="nvetude" className="block text-sm font-medium leading-6 text-gray-900">
                                    Niveau d’étude & formation professionnelle
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="nvetude"
                                        name="nvetude"
                                        type="text"
                                        autoComplete="nvetude"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        

                                    />
                                </div>

                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="exppro" className="block text-sm font-medium leading-6 text-gray-900">
                                    Expérience professionelle
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="exppro"
                                        name="exppro"
                                        type="number"
                                        autoComplete="exppro"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                    />
                                </div>

                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="num" className="block text-sm font-medium leading-6 text-gray-900">
                                    Numéro de télephone mobile
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="num"
                                        name="num"
                                        type="text"
                                        autoComplete="num"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                    />
                                </div>

                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="numbesoin" className="block text-sm font-medium leading-6 text-gray-900">
                                    Personne de contact en cas de besoin
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="numbesoin"
                                        name="numbesoin"
                                        type="text"
                                        autoComplete="numbesoin"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="border-b border-gray-900/10 pb-12">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Identité du projet</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Cette section concerne l'idée que vous avez envie de soumettre.</p>

                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-6">
                                <label htmlFor="ideas" className="block text-sm font-medium leading-6 text-gray-900">
                                    Titre de l'idée d'entreprise
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="ideas"
                                        id="ideas"
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setPrenom(e.target.value)} value={prenom}
                                        placeholder='Ex: ADPME'
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-6">
                                <label htmlFor="ideas" className="block text-sm font-medium leading-6 text-gray-900">
                                    Domaines d'activités
                                </label>
                                {
                                    secteurs.map((sec) => (
                                        <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700" key={sec.id}>
                                            <input onChange={handleChangeActivity} id="bordered-checkbox-1" type="checkbox" value={sec.id} name="bordered-checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="bordered-checkbox-1" className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{sec.libelleSecteur}</label>
                                        </div>
                                    ))
                                }
                                
                            </div>
                        </div>
                    </div>
                    <div className="border-b border-gray-900/10 pb-12">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Synthèse de votre idée d'entreprise</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Cette section concerne l'idée que vous avez envie de soumettre.</p>

                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-6">
                                <label htmlFor="opp" className="block text-sm font-medium leading-6 text-gray-900">
                                    Opportunité d'entreprendre
                                </label>
                                <div className="mt-2">
                                    <textarea
                                        type="text"
                                        name="opp"
                                        id="opp"
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setOpp(e.target.value)} value={opp}
                                        placeholder='Expliquer pourquoi voulez-vous entreprendre (soyez concis et précis)'
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-6">
                                <label htmlFor="offre" className="block text-sm font-medium leading-6 text-gray-900">
                                    Offre et Activité
                                </label>
                                <div className="mt-2">
                                    <textarea
                                        type="text"
                                        name="offre"
                                        id="offre"
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setOffer(e.target.value)} value={offer}
                                        placeholder='Que voulez-vous offrir et expliquer en quoi consiste votre activité'
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-6">
                                <label htmlFor="market" className="block text-sm font-medium leading-6 text-gray-900">
                                    Marché
                                </label>
                                <div className="mt-2">
                                    <textarea
                                        type="text"
                                        name="market"
                                        id="market"
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setMarket(e.target.value)} value={market}
                                        placeholder='Où voulez-vous écouler ce que vous offrez et comment cela va se faire'
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-6">
                                <label htmlFor="carac" className="block text-sm font-medium leading-6 text-gray-900">
                                    Caractère Innovant de l'Idée
                                </label>
                                <div className="mt-2">
                                    <textarea
                                        type="text"
                                        name="carac"
                                        id="carac"
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        placeholder="Quelle est l'innovation que vous apportez pour faire la différence sur le marché"
                                        onChange={(e) => setCaract(e.target.value)} value={caract}

                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-6">
                                <label htmlFor="experience" className="block text-sm font-medium leading-6 text-gray-900">
                                    Votre expérience
                                </label>
                                <div className="mt-2">
                                    <textarea
                                        type="text"
                                        name="experience"
                                        id="experience"
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setYourExp(e.target.value)} value={yourExp}
                                        placeholder="Quelle est votre expérience dans le domaine d'activité projetée ? stage professionnel, expérience professionnelle, formation pratique et renforcement de capacité, incubation, etc."
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-6">
                                <label htmlFor="attente" className="block text-sm font-medium leading-6 text-gray-900">
                                    Vos attentes
                                </label>
                                <div className="mt-2">
                                    <textarea
                                        type="text"
                                        name="attente"
                                        id="attente"
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        placeholder="Qu'attendez-vous de l'Agence de Développement des Petites et Moyennes Entreprises ? Avez-vous des attentes particulières ? si oui formulez-les"
                                        onChange={(e) => setYourAttent(e.target.value)} value={yourAttent}

                                    />
                                </div>
                            </div>
                            <div className="col-span-full">
                                <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                                    Pièces d'identité
                                </label>
                                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <div className="text-center">
                                        <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                            <label
                                                htmlFor="file-upload"
                                                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                            >
                                                <span>Glisser un fichier</span>
                                                <input onChange={handleFileChange} id="file-upload" name="file-upload" type="file" className="sr-only" />
                                            </label>
                                        </div>
                                        <p className="text-xs leading-5 text-gray-600">Max 5MB</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-full">
                                <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                                    Diplômes, certificats de formation, attestations de stage (Vous pouvez tout mettre en un fichier pdf).
                                </label>
                                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <div className="text-center">
                                        <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                            <label
                                                htmlFor="file-upload"
                                                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                            >
                                                <span>Glisser un fichier</span>
                                                <input onChange={handleFileChange2} id="file-upload" name="file-upload" type="file" className="sr-only" />
                                            </label>
                                        </div>
                                        <p className="text-xs leading-5 text-gray-600">Max 10MB</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                        Abandonner
                    </button>
                    <button
                        type="submit"
                        className="rounded-md bg-amber-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
                        disabled
                    >
                        Soumettre
                    </button>
                </div>
            </form>
            {/* <Box sx={{ maxWidth: 900 }}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel
                                optional={
                                    index === 2 ? (
                                        <Typography variant="caption">Dernière étape</Typography>
                                    ) : null
                                }
                            >
                                {step.label}
                            </StepLabel>
                            <StepContent>
                                <Typography>
                                    {step.label == 'Informations personnelles' ? (
                                        <div className="space-y-6">
                                            <div>
                                                <img src={img} />
                                            </div>
                                            <br />
                                             <div>
                                                <div className="mb-2 block">
                                                    <Label htmlFor="nom" value={<span className='font-bold'>Nom(s) et prénoms(s) <span className='text-red-500'>*</span></span>} />
                                                </div>
                                                <TextInput onChange={(e) => setFullName(e.target.value)} value={fullName} id="nom" placeholder='Ex: John Doe' type="text" required />
                                            </div> 
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label htmlFor="datenais" value={<span className='font-bold'>Date de naissance <span className='text-red-500'>*</span></span>} />
                                                </div>
                                                <TextInput onChange={(e) => setOld(e.target.value)} value={old} id="datenais" type="date" required />
                                            </div>
                                            <div>
                                                <FormControl>
                                                    <FormLabel><span className='font-bold'>Genre</span></FormLabel>
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        defaultValue="female"
                                                        name="radio-buttons-group"
                                                        value={genre}
                                                        onChange={handleRadioChange}
                                                    >
                                                        <FormControlLabel value="HOMME" control={<Radio />} label="Femme" />
                                                        <FormControlLabel value="FEMME" control={<Radio />} label="Homme" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label htmlFor="nvetude" value={<span className='font-bold'>Niveau d’étude & formation professionnelle <span className='text-red-500'>*</span></span>} />
                                                </div>
                                                <TextInput onChange={(e) => setNvEtude(e.target.value)} value={nvEtude} id="nvetude" placeholder='Parcours professionnel' type="text" required />
                                            </div>
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label htmlFor="exppro" value={<span className='font-bold'>Expérience professionelle <span className='text-red-500'>*</span></span>} />
                                                </div>
                                                <Textarea onChange={(e) => setExpPro(e.target.value)} value={expPro} id="exppro" placeholder='Renseigner ici votre expérience professionnelle sur les 3 dernières années...' type="text" required />
                                            </div>
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label htmlFor="expyear" value={<span className='font-bold'>Année d'expérience du projet <span className='text-red-500'>*</span></span>} />
                                                </div>
                                                <Textarea onChange={(e) => setExpYear(e.target.value)} value={expYear} id="expyear" placeholder='Renseigner ici votre expérience professionnelle sur les 3 dernières années...' type="text" required />
                                            </div>
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label htmlFor="adresse" value={<span className='font-bold'>Adresse physique de l'entreprise (Carré, Lot, ect...) <span className='text-red-500'>*</span></span>} />
                                                </div>
                                                <TextInput onChange={(e) => setAdresse(e.target.value)} value={adresse} id="adresse" placeholder='Ex: c/20XX Lot X' type="text" required />
                                            </div>
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label htmlFor="dept" value={<span className='font-bold'>Département <span className='text-red-500'>*</span></span>} />
                                                </div>
                                                <Select onChange={deptChoose} value={dept} id="dept" required>
                                                    <option disabed selected>Sélectionner un département</option>
                                                    {depts.length > 0 ?
                                                        depts.map((sp, index) => {
                                                            return (
                                                                <option key={index} value={sp.id}>{sp.libelle}</option>
                                                            )
                                                        }) :
                                                        ("Aucun départements")}
                                                </Select>
                                            </div>
                                            {dept != 0 ? (
                                                <>
                                                    <div>
                                                        <div className="mb-2 block">
                                                            <Label htmlFor="com" value={<span className='font-bold'>Commune <span className='text-red-500'>*</span></span>} />
                                                        </div>
                                                        <Select onChange={comChoose} value={commune} id="com" required>
                                                            <option disabed selected>Sélectionner une commune</option>
                                                            {communesChoose.length > 0 ?
                                                                communesChoose.map((sp, index) => {
                                                                    return (
                                                                        <option key={index} value={sp.id}>{sp.libelle}</option>
                                                                    )
                                                                }) :
                                                                ("Aucune commune")}
                                                        </Select>
                                                    </div>
                                                    {commune != 0 ? (
                                                        <>
                                                            <div>
                                                                <div className="mb-2 block">
                                                                    <Label htmlFor="arrondissement" value={<span className='font-bold'>Arrondissement <span className='text-red-500'>*</span></span>} />
                                                                </div>
                                                                <Select onChange={arrChoose} value={arrondissement} id="arrondissement" required>
                                                                    <option disabed selected>Sélectionner un arrondissement</option>
                                                                    {arrondissementsChoose.length > 0 ?
                                                                        arrondissementsChoose.map((sp, index) => {
                                                                            return (
                                                                                <option key={index} value={sp.id}>{sp.libelle}</option>
                                                                            )
                                                                        }) :
                                                                        ("Aucun arrondissement")}
                                                                </Select>
                                                            </div>
                                                            {arrondissement != 0 ? (
                                                                <div>
                                                                    <div className="mb-2 block">
                                                                        <Label htmlFor="quartier" value={<span className='font-bold'>Village ou quartier de ville <span className='text-red-500'>*</span></span>} />
                                                                    </div>
                                                                    <Select onChange={(e) => setVillageOuQuartier(e.target.value)} value={villageOuQuartier} id="quartier" required>
                                                                        <option disabed selected>Sélectionner votre village ou votre quartier</option>
                                                                        {vilsChoose.length > 0 ?
                                                                            vilsChoose.map((sp, index) => {
                                                                                return (
                                                                                    <option key={index} value={sp.id}>{sp.libelle}</option>
                                                                                )
                                                                            }) :
                                                                            ("Aucun Village ou quartier")}
                                                                    </Select>
                                                                </div>
                                                            ) : ("")}
                                                        </>
                                                    ) : ("")}
                                                </>
                                            ) : ("")}
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label htmlFor="phone" value={<span className='font-bold'>Numéro de télephone mobile <span className='text-red-500'>*</span></span>} />
                                                </div>
                                                <TextInput onChange={(e) => setTel(e.target.value)} value={tel} id="phone" placeholder='Ex:0022960XXXXXX' type="text" required />
                                            </div>
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label htmlFor="mail" value={<span className='font-bold'>Adresse email <span className='text-red-500'>*</span></span>} />
                                                </div>
                                                <TextInput onChange={(e) => setMail(e.target.value)} value={mail} id="mail" placeholder='Ex: johndoe@gmail.com' type="text" required />
                                            </div>
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label htmlFor="phone2" value={<span className='font-bold'>Personne de contact en cas de besoin <span className='text-red-500'>*</span></span>} />
                                                </div>
                                                <TextInput onChange={(e) => setOtherTel(e.target.value)} value={otherTel} id="phone2" placeholder='Ex:0022960XXXXXX' type="text" required />
                                            </div>
                                        </div>
                                    ) : step.label == 'Identité du projet' ? (
                                        <div className="space-y-6">
                                            <div>
                                                <img src={img2} />
                                            </div>
                                            <br />
                                            <div>
                                                <div className="mb-2 block">
                                                        <Label htmlFor="ideas" value={<span className='font-bold'>Titre de l'idée d'entreprise <span className='text-red-500'>*</span></span>} />
                                                </div>
                                                <TextInput onChange={(e) => setIdeas(e.target.value)} value={ideas} id="ideas" placeholder='Ex: ADPME' type="text" required />
                                            </div>
                                            <div>
                                                <FormControl>
                                                    <FormLabel><span className='font-bold'>Domaines d'activités</span></FormLabel>
                                                   {
                                                    secteurs.map((sec) => (
                                                        <FormControlLabel
                                                            key={sec.id}
                                                            control={
                                                                <Checkbox checked={secteur[sec.id]} onChange={handleChangeActivity} name={sec.libelleSecteur} />
                                                            }
                                                            label={sec.libelleSecteur}
                                                        />
                                                        ))
                                                   }
                                                </FormControl>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div>
                                                <img src={img3} />
                                            </div>
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label htmlFor="opp" value={<span className='font-bold'>Opportunité d'entreprendre <span className='text-red-500'>*</span></span>} />
                                                </div>
                                                <Textarea onChange={(e) => setOpp(e.target.value)} value={opp} id="opp" placeholder='Expliquer pourquoi voulez-vous entreprendre (soyez concis et précis)' type="text" required />
                                            </div>
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label htmlFor="offer" value={<span className='font-bold'>Offre et Activité <span className='text-red-500'>*</span></span>} />
                                                </div>
                                                <Textarea onChange={(e) => setOffer(e.target.value)} value={offer} id="offer" placeholder='Que voulez-vous offrir et expliquer en quoi consiste votre activité' type="text" required />
                                            </div>
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label htmlFor="market" value={<span className='font-bold'>Marché <span className='text-red-500'>*</span></span>} />
                                                </div>
                                                <Textarea onChange={(e) => setMarket(e.target.value)} value={market} id="market" placeholder='Où voulez-vous écouler ce que vous offrez et comment cela va se faire' type="text" required />
                                            </div>
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label htmlFor="caract" value={<span className='font-bold'>Caractère Innovant de l'Idée <span className='text-red-500'>*</span></span>} />
                                                </div>
                                                        <Textarea onChange={(e) => setCaract(e.target.value)} value={caract} id="caract" placeholder="Quelle est l'innovation que vous apportez pour faire la différence sur le marché" type="text" required />
                                            </div>
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label htmlFor="yourExp" value={<span className='font-bold'>Votre expérience <span className='text-red-500'>*</span></span>} />
                                                </div>
                                                        <Textarea onChange={(e) => setYourExp(e.target.value)} value={yourExp} id="yourExp" placeholder="Quelle est votre expérience dans le domaine d'activité projetée ? stage professionnel, expérience professionnelle, formation pratique et renforcement de capacité, incubation, etc." type="text" required />
                                            </div>
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label htmlFor="yourAttent" value={<span className='font-bold'>Votre expérience <span className='text-red-500'>*</span></span>} />
                                                </div>
                                                        <Textarea onChange={(e) => setYourAttent(e.target.value)} value={yourAttent} id="yourAttent" placeholder="Qu\'attendez-vous de l\'Agence de Développement des Petites et Moyennes Entreprises ? Avez-vous des attentes particulières ? si oui formulez-les" type="text" required />
                                            </div>
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label htmlFor="annexe" value={<span className='font-bold'>Annexes (Diplômes, certificats de formation, attestations de stage) <span className='text-red-500'>*</span></span>} />
                                                </div>
                                                <FileInput onChange={handleFileChange} id="file1" required />
                                                <FileInput onChange={handleFileChange2} id="file2" required />
                                            </div>
                                        </>
                                    )}
                                </Typography>
                                <Box sx={{ mb: 2, mt: 4 }}>
                                    <div className='inline-flex gap-2'>
                                        <Button
                                            onClick={index === steps.length - 1 ? handleSubmit : handleNext}
                                            color=""
                                            className='rounded-l-lg rounded-tr-lg inline-flex items-center text-sm font-medium text-center text-white bg-amber-700 rounded-lg hover:bg-amber-800'

                                        >
                                            {index === steps.length - 1 ? 'Terminé' : 'Continuer'}
                                        </Button>
                                        <Button
                                            disabled={index === 0}
                                            onClick={handleBack}
                                            color=""
                                            className='rounded-l-lg rounded-tr-lg inline-flex items-center text-sm font-medium text-center text-white bg-amber-700 rounded-lg hover:bg-amber-800'

                                        >
                                            Retour
                                        </Button>
                                    </div>
                                </Box>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length && (
                    <Paper square elevation={0} sx={{ p: 3 }}>
                        <Typography>Tous les étapes sont completer - Vous avez finis</Typography>
                        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                            Réinitialiser
                        </Button>
                    </Paper>
                )}
            </Box> */}
            
            
        </div>
    );
}//detpt, commune, arrondissement, village ou quartiers et adresse(carré et autre), ajouter capital sociale(étape deux), nbre employer (étape 3), donner la possibilité d'enregistrer les associées selon si l'entreprise existe