import React from "react";

const urlstandart = process.env.REACT_APP_URL_STANDART;

function TopActualiteCard(props) {
  const dateevent = new Date(props.date).toISOString().split("T")[0];
  console.log(props.etiquette)
  return (
    <>
      <div className="max-w-sm h-[450px] bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <div className="w-full h-40 overflow-hidden rounded-t-lg">
          <a href="#">
            {props.etiquette ? (
              props.etiquette === "EVENEMENT" ? (
                <a href={`/details-evenement/${props.titre}`}>
                  <img
                    crossOrigin="anonymous"
                    src={`${urlstandart}api/${props.imgevent}`}
                    className="rounded-t-lg hover:scale-125 hover:duration-700 transition ease-in-out w-full"
                    alt="office content 2"
                  />
                </a>
              ) : props.etiquette === "APPEL_A_PROJET" ? (
                <a href={`/details-projets/${props.titre}`}>
                  <img
                    crossOrigin="anonymous"
                    src={`${urlstandart}api/${props.imgprojet}`}
                    className="rounded-t-lg hover:scale-125 hover:duration-700 transition ease-in-out w-full"
                    alt="office content 2"
                  />
                </a>
              ) : (
                <a href={`/details-communiques/${props.titre}`}>
                  <img
                    crossOrigin="anonymous"
                    src={`${urlstandart}api/${props.imgcom}`}
                    className="rounded-t-lg hover:scale-125 hover:duration-700 transition ease-in-out w-full"
                    alt="office content 2"
                  />
                </a>
              )
            ) : (
              ""
            )}
          </a>
        </div>
        <div className="p-5">
          {props.etiquette ? (
            props.etiquette === "EVENEMENT" ? (
              <button className="p-1 border-2 border-green-500 font-bold text-green-500 w-48 rounded text-center">
                EVENEMENT
              </button>
            ) : props.etiquette === "APPEL_A_PROJET" ? (
              <button className="p-1 border-2 border-green-500 font-bold text-green-500 w-48 rounded text-center">
                PROJET
              </button>
            ) : (
              <button className="p-1 border-2 border-green-500 font-bold text-green-500 w-48 rounded text-center">
                COMMUNIQUE
              </button>
            )
          ) : (
            ""
          )}
          <div className="pt-5">
            {props.etiquette ? (
              props.etiquette === "EVENEMENT" ? (
                <a href={`/details-evenement/${props.titre}`}>
                  <h5 className="line-clamp-4 mb-2 text-xl font-bold tracking-tight text-neutral-900 dark:text-white">
                    {props.titre}
                  </h5>
                </a>
              ) : props.etiquette === "APPEL_A_PROJET" ? (
                <a href={`/details-projets/${props.titre}`}>
                  <h5 className="line-clamp-4 mb-2 text-xl font-bold tracking-tight text-neutral-900 dark:text-white">
                    {props.titre}
                  </h5>
                </a>
              ) : (
                <a href={`/details-communiques/${props.titre}`}>
                  <h5 className="line-clamp-4 mb-2 text-xl font-bold tracking-tight text-neutral-900 dark:text-white">
                    {props.titre}
                  </h5>
                </a>
              )
            ) : (
              ""
            )}
          </div>
          <div className="flex flex-row items-center pt-3 pb-10">
            <svg
              fill="#000000"
              width={21}
              height={21}
              viewBox="0 0 1024 1024"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M960 95.888l-256.224.001V32.113c0-17.68-14.32-32-32-32s-32 14.32-32 32v63.76h-256v-63.76c0-17.68-14.32-32-32-32s-32 14.32-32 32v63.76H64c-35.344 0-64 28.656-64 64v800c0 35.343 28.656 64 64 64h896c35.344 0 64-28.657 64-64v-800c0-35.329-28.656-63.985-64-63.985zm0 863.985H64v-800h255.776v32.24c0 17.679 14.32 32 32 32s32-14.321 32-32v-32.224h256v32.24c0 17.68 14.32 32 32 32s32-14.32 32-32v-32.24H960v799.984zM736 511.888h64c17.664 0 32-14.336 32-32v-64c0-17.664-14.336-32-32-32h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32zm0 255.984h64c17.664 0 32-14.32 32-32v-64c0-17.664-14.336-32-32-32h-64c-17.664 0-32 14.336-32 32v64c0 17.696 14.336 32 32 32zm-192-128h-64c-17.664 0-32 14.336-32 32v64c0 17.68 14.336 32 32 32h64c17.664 0 32-14.32 32-32v-64c0-17.648-14.336-32-32-32zm0-255.984h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32h64c17.664 0 32-14.336 32-32v-64c0-17.68-14.336-32-32-32zm-256 0h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32h64c17.664 0 32-14.336 32-32v-64c0-17.68-14.336-32-32-32zm0 255.984h-64c-17.664 0-32 14.336-32 32v64c0 17.68 14.336 32 32 32h64c17.664 0 32-14.32 32-32v-64c0-17.648-14.336-32-32-32z" />
            </svg>
            &nbsp;&nbsp;&nbsp;
            <span className="text-black font-bold">{dateevent}</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopActualiteCard;
