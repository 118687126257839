import React, { useState, useEffect } from "react";
import { RecrutementsListe } from "../../../NoSQL";
import { Dropdown } from "flowbite-react";
import OpportuniteCard from "../../../default_components/OpportuniteCard/OpportuniteCard";
import axios from "axios";
import ConseilMentoratCard from "../../../default_components/ConseilMentoratCard/ConseilMentoratCard";
import Calendar from "../Images/calendrier.png";
import { Button } from "@material-tailwind/react";
import AppelsOffresCard from "../../../default_components/AppelsOffresCard/AppelsOffresCard";

const urlstandart = process.env.REACT_APP_URL_STANDART;

function AppelsOffres(props) {
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    const newurl = process.env.REACT_APP_URL_STANDART + "api/offers";
    axios
      .get(newurl, { withCredentials: true })
      .then((response) => {
        setOffers(response.data.data);
        console.log(response.data.data);
      })
      .catch((err) => {
        alert("Erreur lors de la récupération des informations");
      });
  }, []);
  return (
    <>
      <div className="lg:py-10 font-['Montserrat'] bg-white">
        <div className="grid grid-rows-2 pb-1 gap-4 max-md:pb-5 max-md:flex max-md:flex-col">
          <div>
            <h2 className="text-4xl max-md:text-xl font-semibold leading-7 text-neutral-900 pb-3 font-[' Montserrat']">
              Appels d'offres
            </h2>
            <p className="mt-1 font-normal text-3xs leading-6 text-gray-500 font-[' Montserrat'] pb-2">
              <div>Consulter la liste des appels d'offres</div>
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-20">
          {offers.length <= 0 ? 
            <div>Aucun appel d'offre pour le moment</div> 
          : 
          offers.map((el) => {
            return (
              <>
                <AppelsOffresCard id={el.id} title={el.title} description={el.description} startDate={el.startDate} endDate={el.endDate} notice={el.notice} type={el.typeCallOffer.title} />
                <div className="bg-gray-200 h-0.5 w-full"></div>
              </>
            )
          })}
        </div>
        {/* <div className="max-md:flex max-md:flex-col grid grid-cols-3 gap-4">
                    

                </div> */}
      </div>
      {/* <div className='max-xl:px-4 px-14 mx-auto max-w-screen-xl text-left py-24 lg:py-15 sm:py-15'>
                <div className='mb-10'>
                    <span className='text-4xl font-bold'>Nos coachs d'emploi</span>
                </div>
                
            </div> */}
    </>
  );
}

export default AppelsOffres;
