import React, {useEffect} from 'react';
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
} from "@material-tailwind/react";
import { useParams, useNavigate } from 'react-router-dom';
import Offre from './Offre';
import FormCandidature from './FormCandidature';
import Reseautage from './Reseautage';
import ConseilsMentorat from './ConseilsMentorat';
import OffreMPME from './OffreMPME';
import OffreSAE from './OffreSAE';
import AppelsOffres from './AppelsOffres';

function TabsComponentsRecrutements(props) {
    const { id } = useParams()
    const [activeTab, setActiveTab] = React.useState(id || "recrutementperson");
    const nav = useNavigate()
    
    
    const handleTabClick = (value) => {
        setActiveTab(value);
        nav(`./${value}`);
    };

    useEffect(() => {
        console.log(id)
        nav(`/recrutement`);
    }, [id]);

    
    const data = [
        {
            label: "RECRUTEMENTS PERSONNEL",
            value: "recrutementperson",
            desc: <><Offre /><FormCandidature /></>,
        },
        {
            label: "RECRUTEMENTS SAE",
            value: "recrutementsae",
            desc: <OffreSAE />,
        },
        {
            label: "SELECTIONS MPME",
            value: "selectionmpme",
            desc: <OffreMPME />,
        },
        // {
        //     label: "RÉSEAUTAGE",
        //     value: "réseau",
        //     desc: <Reseautage />,
        //     disabledTabs: true,
        // },
        // {
        //     label: "MARCHÉS DES SERVICES",
        //     value: "marches",
        //     desc: '<PMEForm />',
        //     disabledTabs: true,
        // },
        {
            label: "CONSEILS ET MENTORAT",
            value: "conseils",
            desc: <ConseilsMentorat />,
        },
        {
            label: "APPELS D'OFFRES",
            value: "offres",
            desc: <AppelsOffres />,
        },
    ];
    return (
        <>
            <div className="border-b border-blue-gray-50 font-[' Montserrat']">
                <Tabs value={activeTab}>
                    <TabsHeader
                        className="rounded-none bg-white w-full lg:pr-60 lg:pl-32"
                        indicatorProps={{
                            className:
                                "bg-transparent border-b-2 border-yellow-300 shadow-none rounded-none p-7 w-full",
                        }}
                    >
                        {data.map(({ label, value, disabledTabs }) => (
                            <Tab
                                disabled={disabledTabs ? true : false}
                                key={value}
                                value={value}
                                onClick={() => handleTabClick(value)}
                                className={activeTab === value ? "text-gray-900 font-semibold p-7 w-[24%] max-md:w-[20%] font-[' Montserrat']" : "font-[' Montserrat'] p-7 w-[24%] max-md:w-[20%] font-semibold"}
                            >
                                <span className='max-md:text-[8px]'>{label}</span>
                            </Tab>
                        ))}
                    </TabsHeader>
                    {data.map(({ value, desc }) => (
                        <TabsBody className={`lg:pl-32 lg:pr-72 ${value === 'offres' ? 'bg-white lg:pr-50' : ''}`}>
                            <TabPanel key={value} value={value}>
                                {desc}
                            </TabPanel>
                        </TabsBody>
                    ))}
                </Tabs>
            </div>
        </>
    );
}

export default TabsComponentsRecrutements;