// components/ChallengeSection.jsx
import React from 'react';

const ChallengeSection = () => {
  return (
    <section className="py-16 bg-gray-100 text-gray-800">
      <div className="container mx-auto px-8 flex flex-col md:flex-row">
        <div className="md:w-1/2 mb-8 md:mb-0">
          <h2 className="text-3xl font-semibold mb-4">Les Défis des MPME au Bénin</h2>
          <p className="mb-4">Les micros, petites et moyennes entreprises (MPME) sont essentielles pour l'économie béninoise, mais elles font face à des défis majeurs.</p>
        </div>
        <div className="md:w-1/2 grid grid-cols-1 gap-4 sm:grid-cols-2">
          <div className="bg-white shadow-md p-4 rounded">
            <h3 className="font-bold">Accès restreint au financement</h3>
          </div>
          <div className="bg-white shadow-md p-4 rounded">
            <h3 className="font-bold">Manque de compétences techniques</h3>
          </div>
          <div className="bg-white shadow-md p-4 rounded">
            <h3 className="font-bold">Accès limité au marché</h3>
          </div>
          <div className="bg-white shadow-md p-4 rounded">
            <h3 className="font-bold">Rétention des talents qualifiés</h3>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChallengeSection;
