// components/ObjectivesSection.jsx
import React, { useEffect } from 'react';
import { gsap } from 'gsap';

const ObjectivesSection = () => {
  useEffect(() => {
    gsap.from('.objective-item', {
      opacity: 0,
      y: 20,
      duration: 1,
      stagger: 0.15,
      ease: 'power3.out',
    });
  }, []);

  const objectives = [
    { title: "Renforcement des Capacités", description: "Des ateliers et formations pour améliorer les compétences techniques.", icon: "📈" },
    { title: "Appui Structurel", description: "Développer un soutien solide et un environnement d’encadrement adapté.", icon: "🏢" },
    { title: "Gestion des RH", description: "Assurer la gestion des talents et des ressources humaines de manière efficace.", icon: "👥" }
  ];

  return (
    <section className="py-20 bg-gray-50 px-8 lg:px-20">
      <h2 className="text-4xl font-bold text-center mb-12 text-gray-900">Objectifs et Activités</h2>
      <div className="flex flex-col lg:flex-row gap-10 items-start">
        {objectives.map((obj, index) => (
          <div key={index} className="objective-item flex items-start space-x-4 lg:w-1/3 p-4 border-l-4 border-blue-500">
            {/* Icône réduite et cercle coloré */}
            <div className="text-3xl text-blue-500 bg-blue-100 rounded-full p-3 flex items-center justify-center">
              {obj.icon}
            </div>
            {/* Texte de l'objectif */}
            <div>
              <h3 className="text-xl font-semibold mb-2 text-gray-800">{obj.title}</h3>
              <p className="text-gray-600">{obj.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ObjectivesSection;
