// components/ResultsSection.jsx
import React from 'react';
// import backgroundImage from '/path-to-your-image.jpg'; // Importer l'image
import imgMetho from "../Images/imgMetho.jpg";

const ResultsSection = () => {
  return (
    <section className="relative py-16 px-6 md:px-12" style={{ backgroundImage: `url(${imgMetho})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="absolute inset-0 bg-black opacity-30" /> {/* Overlay pour assombrir l'image */}
      <h2 className="text-3xl font-semibold text-center text-white mb-10">Résultats Actuels</h2>
      <div className="container mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-4 relative z-10">
        <div className="flex flex-col items-center justify-center p-6 rounded-lg shadow-lg bg-gradient-to-r from-blue-100 to-blue-200">
          <h3 className="text-5xl font-bold text-blue-600 mb-2">405</h3>
          <p className="text-lg font-medium text-gray-700 text-center">Emplois créés</p>
        </div>
        <div className="flex flex-col items-center justify-center p-6 rounded-lg shadow-lg bg-gradient-to-r from-green-100 to-green-200">
          <h3 className="text-4xl font-bold text-green-600 mb-2">243</h3>
          <p className="text-lg font-medium text-gray-700 text-center">Conditions de travail améliorées</p>
        </div>
        <div className="flex flex-col items-center justify-center p-6 rounded-lg shadow-lg bg-gradient-to-r from-yellow-100 to-yellow-200 col-span-2">
          <h3 className="text-5xl font-bold text-yellow-600 mb-2">43%</h3>
          <p className="text-lg font-medium text-gray-700 text-center">Augmentation du chiffre d'affaires</p>
        </div>
      </div>
    </section>
  );
};

export default ResultsSection;
