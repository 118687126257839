import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import TopActualiteCard from '../../../default_components/TopActualiteComponent/TopActualiteCard';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const urlstandart = process.env.REACT_APP_URL_STANDART;

function Actualite(props) {
  const [actus, setActus] = useState([]);
  const [imginfoevent, setImgInfoEvent] = useState('');
  const [imginfoprojet, setImgInfoProjet] = useState('');
  const [imginfocom, setImgInfoCom] = useState('');
  const sectionRef = useRef(null);
  const topActualitesRef = useRef(null);
  const aLaUneDeLAgenceRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const eventsResponse = await axios.get(`${urlstandart}api/evenements`, { withCredentials: true });
        const projetsResponse = await axios.get(`${urlstandart}api/appelAProjets`, { withCredentials: true });
        const comsResponse = await axios.get(`${urlstandart}api/communiques`, { withCredentials: true });

        let lastEvent = eventsResponse.data.data.evenements[0];
        if (lastEvent) {
          let imginfoevents = lastEvent.actualite.image.split(',')[0].replace(/\\/g, "/");
          setImgInfoEvent(imginfoevents);
          lastEvent = lastEvent.actualite;
        }

        let lastProjet = projetsResponse.data.data.appelAProjets[0];
        if (lastProjet) {
          let imginfoprojets = lastProjet.actualite.image.split(',')[0].replace(/\\/g, "/");
          setImgInfoProjet(imginfoprojets);
          lastProjet = lastProjet.actualite;
        }

        let lastCom = comsResponse.data.data.communiques[0];
        if (lastCom) {
          let imginfocoms = lastCom.actualite.image.split(',')[0].replace(/\\/g, "/");
          setImgInfoCom(imginfocoms);
          lastCom = lastCom.actualite;
        }

        let mytab = [lastEvent, lastProjet, lastCom].filter(element => element !== undefined);
        setActus(mytab);
        console.log(lastEvent)
      } catch (error) {
        console.error('Erreur lors de la récupération des informations', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Animation des cartes au scroll
    gsap.fromTo(
      sectionRef.current.querySelectorAll('.card'), // Sélection des éléments des cartes dans la section
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        stagger: 0.2,
        duration: 2,
        ease: "power2.out",
        scrollTrigger: {
          trigger: sectionRef.current, // Déclenche l'animation lorsque le composant est visible
          start: "top 80%",  // Animation déclenchée lorsque le haut du composant atteint 80% de la hauteur de la fenêtre
          end: "bottom 20%", // Animation terminée lorsque le bas du composant atteint 20% de la hauteur de la fenêtre
          once: false,        // L'animation ne se déclenche qu'une seule fois
        },
      }
    );
  }, [actus]);

  useEffect(() => {
    // Animation des textes
    const tlText = gsap.timeline({
        repeat: -1, // Répétition infinie
        yoyo: true, // Animation dans les deux sens
        defaults: { ease: "none" }
      });
    // Animation pour le texte "À la une de l'Agence"
    tlText.to(aLaUneDeLAgenceRef.current, {
      duration: 2,
      text: "Consultez nos actualités",
      delay: 1,
      ease: "power2.out",
    });
  }, []);

  return (
    <section className="bg-[#E3F2E5] w-full" ref={sectionRef}>
      <div className='lg:px-44 py-24 max-md:px-4'>
        <div>
          <span className="text-yellow-300 max-md:text-[20px] text-[26px] font-bold font-['Montserrat']" ref={topActualitesRef}>Top actualités</span>
        </div>
        <div>
          <span
            className="text-black max-md:text-[26px] text-[36px] font-bold font-['Montserrat']"
            ref={aLaUneDeLAgenceRef}
          >
            À la une de l'Agence
          </span>
        </div>
        <br />
        <div className='max-md:flex-col max-md:flex grid grid-cols-3 gap-4'>
          {actus.map((actualite, index) => (
            <div key={index} className="card">
              <TopActualiteCard
                titre={actualite.title}
                imgevent={imginfoevent}
                imgprojet={imginfoprojet}
                imgcom={imginfocom}
                date={actualite.createdAt}
                etiquette={actualite.etiquette}
              />
            </div>
          ))}
        </div>
        <div className='flex justify-center text-center'>
          <a href={`/actualites`}>
            <div className='flex flex-row items-center pt-5 font-bold text-green-700 cursor-pointer text-xl'>
              <span>Voir toutes les actualités</span>
              <svg className="w-3 h-3 ms-2.5 rtl:rotate-[270deg]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778" />
              </svg>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
}

export default Actualite;
