import React from 'react';
import Description from './Components/Description';

function ProjetSelect(props) {
    return (
        <>
            <Description name={`${props.name}`} />
        </>
    );
}

export default ProjetSelect;