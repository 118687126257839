import React, { useState, useEffect } from 'react';
import { RecrutementsListe } from '../../../NoSQL';
import { Dropdown } from 'flowbite-react';
import OpportuniteCard from '../../../default_components/OpportuniteCard/OpportuniteCard';
import axios from 'axios'
import ConseilMentoratCard from '../../../default_components/ConseilMentoratCard/ConseilMentoratCard';

const urlstandart = process.env.REACT_APP_URL_STANDART

function ConseilsMentorat(props) {
    const [coachs, setCoachs] = useState([])
    const [criteres, setCriteres] = useState(['Diplôme en informatique', 'Compétences techniques en SE', 'Expériences: 10 ans ou plus', 'Capacités de diagnostic', 'Grande capacité d\'analyse', 'Capacité à d\'adapter'])
    useEffect(() => {
        const newurl = process.env.REACT_APP_URL_STANDART + "api/coaches"
        axios.get(newurl, { withCredentials: true })
            .then(response => {
                setCoachs(response.data.data)
                console.log(response.data.data)
            })
            .catch(err => {
                alert('Erreur lors de la récupération des informations')
            })
    }, [])
    return (
        <>
            <div className="lg:py-10 font-['Montserrat']">
                <div className="grid grid-rows-2 pb-1 gap-4 max-md:pb-5 max-md:flex max-md:flex-col">
                    <div>
                        <h2 className="text-4xl max-md:text-xl font-semibold leading-7 text-neutral-900 pb-3 font-[' Montserrat']">La liste des certifiés en coaching entrepreneurial</h2>
                        <p className="mt-1 font-normal text-3xs leading-6 text-gray-500 font-[' Montserrat'] pb-5">
                            <div>
                                Rendez-vous à la mairie de votre localité pour bénéficier de leurs conseils et mentorat.
                            </div>
                        </p>
                    </div>
                </div>
                <div className="max-md:flex max-md:flex-col grid grid-cols-3 gap-4">
                    {coachs.map((el) => {
                        let resinfo = el.profile
                        let tabres = []
                        tabres = resinfo.split(',')
                        resinfo = tabres[0]
                        resinfo = resinfo.replace(/\\/g, "/")
                        return (
                            <>
                                <ConseilMentoratCard nom={el.lastname} prenom={el.firstname} mail={el.email} phone={el.phone} img={resinfo} localite={el.localite.libelle} />
                            </>
                        )
                    })}

                </div>
            </div>
            {/* <div className='max-xl:px-4 px-14 mx-auto max-w-screen-xl text-left py-24 lg:py-15 sm:py-15'>
                <div className='mb-10'>
                    <span className='text-4xl font-bold'>Nos coachs d'emploi</span>
                </div>
                
            </div> */}
        </>
    );
}

export default ConseilsMentorat;