// components/HeroSection.jsx
import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import imgBann from "../Images/Bann.jpg";

const HeroSection = () => {
  useEffect(() => {
    gsap.from('.hero-text', { opacity: 0, scale: 0.8, duration: 1, ease: 'power2.out' });
    gsap.from('.hero-buttons', { opacity: 0, y: 30, delay: 0.5, duration: 1, ease: 'power2.out' });
  }, []);

  return (
    <section className="relative h-screen bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: `url(${imgBann})` }}>
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative text-center text-white p-8 hero-text">
        <h1 className="text-5xl font-bold mb-6">ProPME : Propulser la Croissance des MPME au Bénin</h1>
        <p className="text-xl mb-8">Un projet pour soutenir la croissance économique par l'accompagnement des MPME.</p>
        <div className="hero-buttons space-x-4">
          <button className="bg-blue-600 hover:bg-blue-800 text-white font-semibold py-3 px-6 rounded-lg shadow-lg">Découvrir ProPME</button>
          <button className="bg-white text-blue-600 hover:bg-gray-200 font-semibold py-3 px-6 rounded-lg shadow-lg">Nos partenaires</button>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
