import React from 'react';
import Banniere from '../../../default_pages/banniere/Banniere';
import Description from './Components/Description';

function PostulerSelect(props) {
    return (
        <>
            <Banniere desc1="Opportunités" desc2="& Chances" />
            <Description title={props.name} />
        </>
    );
}

export default PostulerSelect;