// components/PartnershipSection.jsx
import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import imgPart from "../Images/imgPart.jpg";
import {
  FaHandshake,
  FaMoneyCheckAlt,
  FaChartLine,
  FaNetworkWired,
  FaLightbulb,
} from "react-icons/fa";

const PartnershipSection = () => {
  const sectionRef = useRef();
  const imageRef = useRef();

  useEffect(() => {
    // Animation des sections avec GSAP
    gsap.fromTo(
      sectionRef.current.children,
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, stagger: 0.3, duration: 0.8, ease: "power3.out" }
    );

    // Animation de l'image
    gsap.fromTo(
      imageRef.current,
      { scale: 1.1, rotate: 10, opacity: 0 },
      { scale: 1, rotate: 0, opacity: 1, duration: 1.2, ease: "back.out(1.7)" }
    );
  }, []);

  return (
    <section ref={sectionRef} className="bg-white text-gray-800">
      {/* Section 1: Introduction */}
      <div className="py-20 px-8 text-center bg-green-600 text-white">
        <h2 className="text-4xl font-bold mb-4">Partenariat ADME ProPME</h2>
        <p className="max-w-2xl mx-auto text-lg">
          Un partenariat stratégique pour accompagner les PME dans leur
          croissance durable par des ressources, des formations, et un soutien
          ciblé à chaque étape.
        </p>
      </div>

      {/* Section 2: Objectifs du Partenariat avec Image et Grille d'Objectifs */}
      <div className="relative py-16 bg-gray-50 overflow-hidden">
        <div className="container mx-auto flex flex-col md:flex-row items-center">
          {/* Image */}
          <div
            ref={imageRef}
            className="w-full md:w-1/2 h-full bg-blue-700 transform -skew-x-6 overflow-hidden"
          >
            <img
              src={imgPart}
              alt="Objectifs du Partenariat"
              className="w-full h-full object-cover transform skew-x-6 transition-transform duration-300 hover:scale-105"
            />
          </div>

          {/* Grille d'Objectifs */}
          <div className="w-full md:w-1/2 p-8 text-center md:text-left">
            <h3 className="text-3xl font-bold text-blue-700 mb-6">
              Objectifs du Partenariat
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Chaque objectif dans une cellule de grille avec bordure interne */}
              <div className="border-b border-gray-300 p-4 flex items-center">
                <FaMoneyCheckAlt className="text-blue-700 text-3xl mr-4" />
                <p>Accès aux financements</p>
              </div>
              <div className="border-b border-gray-300 p-4 flex items-center">
                <FaHandshake className="text-blue-700 text-3xl mr-4" />
                <p>Formations pour entrepreneurs</p>
              </div>
              <div className="border-b border-gray-300 p-4 flex items-center">
                <FaNetworkWired className="text-blue-700 text-3xl mr-4" />
                <p>Renforcement des réseaux économiques</p>
              </div>
              <div className="border-b border-gray-300 p-4 flex items-center">
                <FaLightbulb className="text-blue-700 text-3xl mr-4" />
                <p>Innovation et digitalisation</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section 3: Avantages pour les PME avec Icônes */}
      <div className="py-16 px-6 md:px-12 bg-white">
        <h3 className="text-3xl font-bold text-center mb-12 text-blue-700">
          Avantages pour les PME
        </h3>
        <div className="grid gap-10 md:grid-cols-2 lg:grid-cols-3 max-w-5xl mx-auto text-center">
          <div className="flex flex-col items-center">
            <FaChartLine className="text-blue-700 text-4xl mb-4" />
            <h4 className="text-xl font-semibold mb-2">
              Croissance Financière
            </h4>
            <p className="text-gray-600">
              Accédez aux financements nécessaires pour la croissance de votre
              entreprise.
            </p>
          </div>
          <div className="flex flex-col items-center">
            <FaHandshake className="text-blue-700 text-4xl mb-4" />
            <h4 className="text-xl font-semibold mb-2">
              Formation et Développement
            </h4>
            <p className="text-gray-600">
              Formations pour renforcer les compétences des entrepreneurs.
            </p>
          </div>
          <div className="flex flex-col items-center">
            <FaNetworkWired className="text-blue-700 text-4xl mb-4" />
            <h4 className="text-xl font-semibold mb-2">
              Accompagnement sur mesure
            </h4>
            <p className="text-gray-600">
              Un suivi adapté pour assurer une croissance durable.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnershipSection;
