import React from 'react';
import { Button } from "@material-tailwind/react";

const urlstandart = process.env.REACT_APP_URL_STANDART

function ConseilMentoratCard(props) {
    
    return (
        <>
            <div className="border-b-8 max-w-sm font-['Montserrat'] p-5 h-[430px] bg-white border-green-700 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <div className="flex justify-center overflow-hidden h-[230px] max-[1440px]:h-[200px] bg-green-50">
                    <img crossOrigin="anonymous" src={`${urlstandart}api/${props.img}`} className="hover:scale-125 hover:duration-700 transition ease-in-out" alt="office content 2" />
                </div>
                <div className='flex justify-center'>
                    <h5 className="text-2xs mt-3 max-md:text-xl font-semibold tracking-tight text-gray-900 dark:text-white line-clamp-1">{props.prenom+' '+props.nom}</h5>
                </div>
                <hr className='bg-gray-700' />
                <div className='min-h-[130px] my-2 text-gray-900 font-normal'>
                    <div className='mb-5'>
                        <span className='mr-3 font-bold text-green-700'>Mail : </span>{props.mail ? props.mail : "-"}
                    </div>
                    <div className='mb-5'>
                        <span className='mr-3 font-bold text-green-700'>Phone : </span>{props.phone ? props.phone : "-"}
                    </div>
                    <div className='mb-5'>
                        <span className='mr-3 font-bold text-green-700'>Localité : </span>{props.localite ? props.localite : "-"}
                    </div>
                    
                </div>
            </div>
        </>
    );
}

export default ConseilMentoratCard;