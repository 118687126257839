import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import CommuniqueCard from '../../../default_components/CommuniqueCard/CommuniqueCard';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { TextPlugin } from 'gsap/TextPlugin';

gsap.registerPlugin(ScrollTrigger, TextPlugin);

const urlstandart = process.env.REACT_APP_URL_STANDART;

function Communique(props) {
    const [communiques, setCommuniques] = useState([]);
    const titleRef = useRef(null);
    const sectionRef = useRef(null);

    useEffect(() => {
        const newurl = process.env.REACT_APP_URL_STANDART + "api/communiques";
        axios.get(newurl, { withCredentials: true })
            .then(response => {
                setCommuniques(response.data.data.communiques);
            })
            .catch(err => {
                alert('Erreur lors de la récupération des informations');
            });
    }, []);

    useEffect(() => {
        if (!titleRef.current || !sectionRef.current) return;

        // Animation des textes
        const tlText = gsap.timeline({
            repeat: -1, // Répétition infinie
            yoyo: true, // Animation dans les deux sens
            defaults: { ease: "none" }
        });

        tlText.to(titleRef.current, {
            duration: 2,
            text: "Consultez nos communiqués",
            delay: 1,
            ease: "power2.out",
        });

        // Animation des cartes au scroll
        gsap.fromTo(
            sectionRef.current.querySelectorAll('.card'), // Sélection des éléments des cartes dans la section
            { opacity: 0, y: 50 },
            {
                opacity: 1,
                y: 0,
                stagger: 0.2,
                duration: 10,
                ease: "power2.out",
                scrollTrigger: {
                    trigger: sectionRef.current, // Déclenche l'animation lorsque le composant est visible
                    start: "top 80%",  // Animation déclenchée lorsque le haut du composant atteint 80% de la hauteur de la fenêtre
                    end: "bottom 20%", // Animation terminée lorsque le bas du composant atteint 20% de la hauteur de la fenêtre
                    once: false,        // L'animation ne se déclenche qu'une seule fois
                },
            }
        );
    }, [communiques]);

    return (
        <>
            <div ref={sectionRef} className="lg:py-10 font-['Montserrat']">
                <div className="grid grid-cols-2 pb-32 max-md:pb-16 max-md:flex max-md:flex-col">
                    <div className="font-bold max-md:text-xl text-4xl text-neutral-900" ref={titleRef}>
                        Communiqués importants
                    </div>
                </div>
                {communiques.length <= 0 ? (
                    <span>Aucun Communiqué pour le moment</span>
                ) : (
                    <div className='lg:grid lg:grid-cols-3 lg:gap-4 max-xl:space-y-2 max-md:flex max-md:flex-col max-md:px-4'>
                        {communiques.map((el) => {
                            let imginfo = el.actualite.image;
                            let tabimg = imginfo.split(',');
                            imginfo = tabimg[0];
                            imginfo = imginfo.replace(/\\/g, "/");

                            const dn = new Date(el.createdAt);
                            const startdate = dn.toISOString().split('T')[0];
                            return (
                                <CommuniqueCard
                                    imgcom={imginfo}
                                    titre={el.actualite.title}
                                    subtitle={el.actualite.subTitle}
                                    date={startdate}
                                    lien={`/details-communiques/${el.actualite.title}`}
                                    className="card" // Ajout de la classe 'card' pour la sélection dans GSAP
                                />
                            );
                        })}
                    </div>
                )}
            </div>
        </>
    );
}

export default Communique;
