import React, { useState, useEffect } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ProjetsListe } from '../../../NoSQL';
import EventImg from '../Images/actuessai.jpg'
import { Radio, RadioGroup } from '@mui/material';
import axios from 'axios'

const urlstandart = process.env.REACT_APP_URL_STANDART

function LesProjets() {
    const [projets, setProjets] = React.useState([]);
    const [value, setValue] = useState('tous');
    const [tabProjet, setTab] = useState(projets);
    const handleChange = async (event) => {
        setValue(event.target.value);

        const filteredProjets = projets.filter((e) => e.status == event.target.value);

        setTab(filteredProjets);
    };
    useEffect(() => {
        const newurl = process.env.REACT_APP_URL_STANDART + "api/appelAProjets"
        axios.get(newurl, { withCredentials: true })
            .then(response => {
                setProjets(response.data.data.appelAProjets)
            })
            .catch(err => {
                alert('Erreur lors de la récupération des informations')
            })
    }, [])
    return (
        <>
            
            <div className='max-xl:px-4 px-20 mx-auto max-w-screen-xl text-center py-24 lg:py-15 sm:py-15'>
                {projets.length <= 0 ? (<span>Aucun projet pour le moment</span>) : 
                    <div className="lg:grid lg:grid-cols-4 lg:gap-4 sm:flex sm:flex-col">
                        <div className="text-left sm:pb-4">
                            <h3 className='font-bold'>ETAT DES PROJETS</h3>
                            <RadioGroup
                                onChange={handleChange}
                                value={value}
                            >
                                <FormControlLabel value="BIENTOT_TERMINE" control={<Radio />} label={<span className='text-sm'>Bientôt terminé</span>} />
                                <FormControlLabel value="OUVERT" control={<Radio />} label={<span className='text-sm'>Ouvert</span>} />
                                <FormControlLabel value="t" control={<Radio />} label={<span className='text-sm'>Terminée</span>} />
                            </RadioGroup>
                        </div>
                        <div className="col-span-3">
                            <div className="grid lg:grid-cols-3 lg:gap-8 text-left grid-cols-2 gap-4">
                                {value == "tous" ?
                                    projets.map((el) => {
                                        let imginfo = el.actualite.image
                                        let tabimg = []
                                        tabimg = imginfo.split(',')
                                        imginfo = tabimg[0]
                                        imginfo = imginfo.replace(/\\/g, "/")

                                        const dn = new Date(el.startDate)
                                        const startdate = dn.toISOString().split('T')[0]
                                        const df = new Date(el.endDate)
                                        const enddate = df.toISOString().split('T')[0]
                                        return (
                                            <div className="max-w-sm bg-white" key={el.id}>
                                                <a href={`/details-projets/${el.actualite.title}`}>
                                                    {el.actualite.image ? (<img crossOrigin="anonymous" src={`${urlstandart}api/${imginfo}`} elt="" />) : ("")}
                                                </a>
                                                <div className="p-5">
                                                    <p>
                                                        <span className='inline-flex'>
                                                            <svg
                                                                fill="#000000"
                                                                width={21} height={21}
                                                                viewBox="0 0 1024 1024"
                                                                xmlns="http://www.w3.org/2000/svg"

                                                            >
                                                                <path d="M960 95.888l-256.224.001V32.113c0-17.68-14.32-32-32-32s-32 14.32-32 32v63.76h-256v-63.76c0-17.68-14.32-32-32-32s-32 14.32-32 32v63.76H64c-35.344 0-64 28.656-64 64v800c0 35.343 28.656 64 64 64h896c35.344 0 64-28.657 64-64v-800c0-35.329-28.656-63.985-64-63.985zm0 863.985H64v-800h255.776v32.24c0 17.679 14.32 32 32 32s32-14.321 32-32v-32.224h256v32.24c0 17.68 14.32 32 32 32s32-14.32 32-32v-32.24H960v799.984zM736 511.888h64c17.664 0 32-14.336 32-32v-64c0-17.664-14.336-32-32-32h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32zm0 255.984h64c17.664 0 32-14.32 32-32v-64c0-17.664-14.336-32-32-32h-64c-17.664 0-32 14.336-32 32v64c0 17.696 14.336 32 32 32zm-192-128h-64c-17.664 0-32 14.336-32 32v64c0 17.68 14.336 32 32 32h64c17.664 0 32-14.32 32-32v-64c0-17.648-14.336-32-32-32zm0-255.984h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32h64c17.664 0 32-14.336 32-32v-64c0-17.68-14.336-32-32-32zm-256 0h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32h64c17.664 0 32-14.336 32-32v-64c0-17.68-14.336-32-32-32zm0 255.984h-64c-17.664 0-32 14.336-32 32v64c0 17.68 14.336 32 32 32h64c17.664 0 32-14.32 32-32v-64c0-17.648-14.336-32-32-32z" />
                                                            </svg>
                                                            &nbsp;
                                                            <span className='text-gray-500 text-sm'><i>{startdate} à {enddate}</i></span>
                                                        </span>
                                                    </p>
                                                    <a href={`/details-projets/${el.actualite.title}`}>
                                                        <h5 className="mb-2 text-sm font-bold tracking-tight text-black-900 dark:text-white">{el.actualite.title}</h5>
                                                    </a>
                                                    <p className="mb-3 font-normal text-sm dark:text-black-200">{el.actualite.subTitle}</p>
                                                    {el.status == "BIENTOT_TERMINE" ? (<span className='bg-amber-500 rounded p-1 text-white'>Bientôt terminée</span>)
                                                        : el.status == "OUVERT" ? (<span className='bg-green-500 rounded p-1 text-white'>Ouvert</span>)
                                                            : (<span className='bg-red-500 rounded p-1 text-white'>Terminée</span>)}

                                                </div>
                                            </div>
                                        )
                                    }) :
                                    tabProjet.map((el) => {
                                        let imginfo = el.actualite.image
                                        let tabimg = []
                                        tabimg = imginfo.split(',')
                                        imginfo = tabimg[0]
                                        imginfo = imginfo.replace(/\\/g, "/")

                                        const dn = new Date(el.startDate)
                                        const startdate = dn.toISOString().split('T')[0]
                                        const df = new Date(el.endDate)
                                        const enddate = df.toISOString().split('T')[0]
                                        return (
                                            <div className="max-w-sm bg-white" key={el.id}>
                                                <a href={`/details-projets/${el.actualite.title}`}>
                                                    {el.actualite.image ? (<img crossOrigin="anonymous" src={`${urlstandart}api/${imginfo}`} elt="" />) : ("")}
                                                </a>
                                                <div className="p-5">
                                                    <p>
                                                        <span className='inline-flex'>
                                                            <svg
                                                                fill="#000000"
                                                                width={21} height={21}
                                                                viewBox="0 0 1024 1024"
                                                                xmlns="http://www.w3.org/2000/svg"

                                                            >
                                                                <path d="M960 95.888l-256.224.001V32.113c0-17.68-14.32-32-32-32s-32 14.32-32 32v63.76h-256v-63.76c0-17.68-14.32-32-32-32s-32 14.32-32 32v63.76H64c-35.344 0-64 28.656-64 64v800c0 35.343 28.656 64 64 64h896c35.344 0 64-28.657 64-64v-800c0-35.329-28.656-63.985-64-63.985zm0 863.985H64v-800h255.776v32.24c0 17.679 14.32 32 32 32s32-14.321 32-32v-32.224h256v32.24c0 17.68 14.32 32 32 32s32-14.32 32-32v-32.24H960v799.984zM736 511.888h64c17.664 0 32-14.336 32-32v-64c0-17.664-14.336-32-32-32h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32zm0 255.984h64c17.664 0 32-14.32 32-32v-64c0-17.664-14.336-32-32-32h-64c-17.664 0-32 14.336-32 32v64c0 17.696 14.336 32 32 32zm-192-128h-64c-17.664 0-32 14.336-32 32v64c0 17.68 14.336 32 32 32h64c17.664 0 32-14.32 32-32v-64c0-17.648-14.336-32-32-32zm0-255.984h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32h64c17.664 0 32-14.336 32-32v-64c0-17.68-14.336-32-32-32zm-256 0h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32h64c17.664 0 32-14.336 32-32v-64c0-17.68-14.336-32-32-32zm0 255.984h-64c-17.664 0-32 14.336-32 32v64c0 17.68 14.336 32 32 32h64c17.664 0 32-14.32 32-32v-64c0-17.648-14.336-32-32-32z" />
                                                            </svg>
                                                            &nbsp;
                                                            <span className='text-gray-500 text-sm'><i>{startdate} à {enddate}</i></span>
                                                        </span>
                                                    </p>
                                                    <a href={`/details-projets/${el.actualite.title}`}>
                                                        <h5 className="mb-2 text-sm font-bold tracking-tight text-black-900 dark:text-white">{el.actualite.title}</h5>
                                                    </a>
                                                    <p className="mb-3 font-normal text-sm dark:text-black-200">{el.actualite.subTitle}</p>
                                                    {el.status == "BIENTOT_TERMINE" ? (<span className='bg-amber-500 rounded p-1 text-white'>Bientôt terminée</span>)
                                                        : el.status == "OUVERT" ? (<span className='bg-green-500 rounded p-1 text-white'>Ouvert</span>)
                                                            : (<span className='bg-red-500 rounded p-1 text-white'>Terminée</span>)}

                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
            
        </>
    );
}

export default LesProjets;