import React from 'react';
import HeroProPME from './Components/HeroProPME';
import ChallengeSection from './Components/ChallengeSection';
import MethodologySection from './Components/MethodologySection';
import ObjectivesSection from './Components/ObjectivesSection';
import ResultsSection from './Components/ResultsSection';
import VisionSection from './Components/VisionSection';
import TestimonialsSection from './Components/TestimonialsSection';
import PartnershipSection from './Components/PartnershipSection';

function ProPME(props) {
    return (
        <>
            <HeroProPME />
            <ChallengeSection />
            <MethodologySection />
            <ObjectivesSection />
            <PartnershipSection />
            <ResultsSection />
            <TestimonialsSection />
            <VisionSection />
        </>
    );
}

export default ProPME;