import React from 'react';

const urlstandart = process.env.REACT_APP_URL_STANDART

function ProjetCard(props) {
    // const dateevent = new Date(props.date).toISOString().split('T')[0]
    return (
        <>
            <div className="max-w-sm h-[450px] font-['Montserrat'] bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <div className='w-full h-40 overflow-hidden rounded-t-lg'>
                    <a href={props.lien}>
                        <img crossOrigin="anonymous" src={`${urlstandart}api/${props.imgprojet}`} className="rounded-t-lg hover:scale-125 hover:duration-700 transition ease-in-out w-full" alt="office content 2" />
                    </a>
                </div>
                <div className='p-10'>
                    <div className='flex flex-row'>

                        {/* {props.status == "OUVERT" ? (<div className='bg-green-700 rounded-full w-5 h-5 m-1'>.</div>)
                                : (<div className='bg-red-600 rounded-full w-5 h-5 m-1'></div>)} */}
                        <div className={((new Date(props.endDate) < Date.now()) ? 'bg-red-600' : 'bg-green-600') + ' rounded-full w-5 h-5 m-1'}></div>
                        <a href={props.lien}>
                            <h5 className="mb-2 text-xl font-semibold tracking-tight text-neutral-900 dark:text-white line-clamp-3">{props.titre}</h5>
                        </a>
                    </div>
                    <div className='flex flex-row items-center'>
                        <svg
                            fill="#000000"
                            width={21} height={21}
                            viewBox="0 0 1024 1024"
                            xmlns="http://www.w3.org/2000/svg"

                        >
                            <path d="M960 95.888l-256.224.001V32.113c0-17.68-14.32-32-32-32s-32 14.32-32 32v63.76h-256v-63.76c0-17.68-14.32-32-32-32s-32 14.32-32 32v63.76H64c-35.344 0-64 28.656-64 64v800c0 35.343 28.656 64 64 64h896c35.344 0 64-28.657 64-64v-800c0-35.329-28.656-63.985-64-63.985zm0 863.985H64v-800h255.776v32.24c0 17.679 14.32 32 32 32s32-14.321 32-32v-32.224h256v32.24c0 17.68 14.32 32 32 32s32-14.32 32-32v-32.24H960v799.984zM736 511.888h64c17.664 0 32-14.336 32-32v-64c0-17.664-14.336-32-32-32h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32zm0 255.984h64c17.664 0 32-14.32 32-32v-64c0-17.664-14.336-32-32-32h-64c-17.664 0-32 14.336-32 32v64c0 17.696 14.336 32 32 32zm-192-128h-64c-17.664 0-32 14.336-32 32v64c0 17.68 14.336 32 32 32h64c17.664 0 32-14.32 32-32v-64c0-17.648-14.336-32-32-32zm0-255.984h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32h64c17.664 0 32-14.336 32-32v-64c0-17.68-14.336-32-32-32zm-256 0h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32h64c17.664 0 32-14.336 32-32v-64c0-17.68-14.336-32-32-32zm0 255.984h-64c-17.664 0-32 14.336-32 32v64c0 17.68 14.336 32 32 32h64c17.664 0 32-14.32 32-32v-64c0-17.648-14.336-32-32-32z" />
                        </svg>
                        &nbsp;&nbsp;&nbsp;
                        <span className="text-black font-normal">{props.date}</span>
                    </div>
                    <div>
                        <h5 className="mb-2 text-5xs font-normal tracking-tight text-gray-600 dark:text-white line-clamp-3">{props.subtitle}</h5>
                    </div>
                    <a className='flex flex-row items-center font-bold text-green-700 cursor-pointer text-sm pt-1' href={props.lien}>
                        <span>Lire l'article</span>
                        <svg className="w-3 h-3 ms-2.5 rtl:rotate-[270deg]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778" />
                        </svg>
                    </a>
                </div>
            </div>
        </>
    );
}

export default ProjetCard;