import React from 'react';
import semecityimg from "../Images/semecitylogo.png"
import ccibeninimg from "../Images/ccibeninlogo.png"
import anpeimg from "../Images/anpe.png"
import fdaimg from "../Images/fda.png"
import cmaimg from "../Images/cma.png"
import afdimg from "../Images/afd.png"
import ueimg from "../Images/UE.png"

function Partenaires(props) {
    return (
        <>
            <div className='lg:px-44 max-md:py-4 py-24 text-center max-md:px-4'>
                <div>
                    <span className="text-neutral-900 max-md:text-[22px] max-md:mb-3 max-md:text-green-700 text-[36px] font-semibold font-['Montserrat']">Nos partenaires de confiance</span>
                </div>
                <div>
                    <span className="text-neutral-900 max-md:text-[16px] text-[20px] font-normal font-['Montserrat']">Découvrez les entreprises et partenaires qui nous font confiance</span>
                </div>
                <br />
                <div className='overflow-hidden grid grid-cols-4 justify-items-center gap-4 items-center max-md:justify-center max-md:items-center max-md:flex max-md:flex-col'>
                    <img className='max-md:w-24 rounded-t-lg hover:scale-125 hover:duration-700 transition ease-in-out' src={anpeimg} />
                    <img className='max-md:w-24 rounded-t-lg hover:scale-125 hover:duration-700 transition ease-in-out' src={fdaimg} />
                    <img className='max-md:w-24 rounded-t-lg hover:scale-125 hover:duration-700 transition ease-in-out' src={cmaimg} />
                    <img className='max-md:w-24 rounded-t-lg hover:scale-125 hover:duration-700 transition ease-in-out' src={ccibeninimg} />
                    <img className='max-md:w-24 rounded-t-lg hover:scale-125 hover:duration-700 transition ease-in-out' src={afdimg} />
                    <img className='max-md:w-24 rounded-t-lg hover:scale-125 hover:duration-700 transition ease-in-out' src={ueimg} />
                    <img className='max-md:w-24 rounded-t-lg hover:scale-125 hover:duration-700 transition ease-in-out' src={semecityimg} />
                    
                </div>
            </div>
        </>
    );
}

export default Partenaires;