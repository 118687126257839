// components/VisionSection.jsx
import React from 'react';

const VisionSection = () => {
  return (
    <section className="relative py-20 bg-gradient-to-r from-blue-600 to-blue-800 text-center text-white">
      <h2 className="text-4xl font-semibold mb-6">Conclusion et Perspectives</h2>
      <p className="text-lg mb-4 max-w-2xl mx-auto">ProPME contribue à un écosystème entrepreneurial dynamique et durable au Bénin. Avec des initiatives innovantes et un soutien constant, nous visons à renforcer l'économie locale et améliorer la qualité de vie.</p>
    </section>
  );
};

export default VisionSection;
