import React from 'react';
import Banniere from '../../default_pages/banniere/Banniere';
import Offre from './Components/Offre';
import FormCandidature from './Components/FormCandidature';
import TabsComponentsRecrutements from './Components/TabsComponentsRecrutement';

function Recrutement(props) {
    return (
        <>
            <div className='bg-[#E3F2E5]'>
                <Banniere desc1="Opportunités" desc2="& Chances" />
                <TabsComponentsRecrutements />
            </div>
        </>
    );
}

export default Recrutement;