// components/ProjectSection.js
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

function ProjectSection({ title, description, objectives, results, areas, funding }) {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <section className="border-b border-gray-300 py-8 transition-shadow duration-300 hover:shadow-lg rounded-lg bg-white p-5">
      {/* Title */}
      <motion.h2
        className="text-3xl font-semibold text-green-700 mb-3"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
      >
        {title}
      </motion.h2>

      {/* Description */}
      <motion.p
        className="mt-2 text-gray-600 text-lg"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        {description}
      </motion.p>

      {/* Button */}
      <button
        onClick={() => setShowDetails(!showDetails)}
        className="mt-4 px-4 py-2 text-white bg-green-600 rounded-full hover:bg-green-700 transition duration-300 transform hover:scale-105 focus:outline-none"
      >
        {showDetails ? "Masquer les détails" : "Voir les détails"}
      </button>

      {/* Details */}
      <AnimatePresence>
        {showDetails && (
          <motion.div
            className="mt-6 space-y-6"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.5 }}
          >
            {/* Objectifs */}
            <div>
              <h3 className="text-xl font-semibold text-green-800">Objectifs</h3>
              {Array.isArray(objectives) ? (
                <ul className="list-disc list-inside pl-4 text-gray-600">
                  {objectives.map((obj, index) => (
                    <li key={index} className="leading-relaxed">
                      {obj}
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-gray-600">{objectives}</p>
              )}
            </div>

            {/* Résultats */}
            <div>
              <h3 className="text-xl font-semibold text-green-800">Résultats</h3>
              {Array.isArray(results) && results.length > 0 ? (
                <ul className="list-disc list-inside pl-4 text-gray-600">
                  {results.map((result, index) => (
                    <li key={index} className="leading-relaxed">
                      {result}
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-gray-600">{results || "Aucun résultat disponible pour l'instant"}</p>
              )}
            </div>

            {/* Zones d'intervention */}
            <div>
              <h3 className="text-xl font-semibold text-green-800">Zones d'intervention</h3>
              <p className="text-gray-600">{areas}</p>
            </div>

            {/* Financement */}
            <div>
              <h3 className="text-xl font-semibold text-green-800">Financement</h3>
              <p className="text-gray-600">{funding}</p>
            </div>

            {/* Informations de contact */}
            <div className="mt-8 bg-gray-100 rounded-lg p-4 border-l-4 border-green-600">
              <h3 className="text-lg font-semibold text-green-700 mb-2">
                Pour toutes informations complémentaires, veuillez contacter :
              </h3>
              <div className="flex items-center text-gray-700 mt-2">
                {/* Icône Téléphone */}
                <svg className="w-5 h-5 text-green-600 mr-2" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.54 16.64c-1.36-1.36-3.17-1.53-4.63-.58l-1.1.73c-.55.37-1.26.29-1.73-.2l-2.54-2.54c-.48-.47-.56-1.18-.2-1.73l.73-1.1c.95-1.46.78-3.27-.58-4.63l-2.1-2.1c-1.53-1.53-4.02-1.53-5.56 0l-.97.97c-.88.88-.88 2.31 0 3.18l2.3 2.3c1.88 1.88 4.56 4.56 8.16 8.16 1.92 1.92 3.64 2.89 5.15 2.89 1.14 0 2.05-.5 2.69-1.14l.97-.97c.88-.88.88-2.31 0-3.18l-2.1-2.1z" />
                </svg>
                <span> +229 41 29 27 22</span>
              </div>
              <div className="flex items-center text-gray-700 mt-2">
                {/* Icône Email */}
                <svg className="w-5 h-5 text-green-600 mr-2" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 13.065l-7.2-5.4c.153-.026.307-.065.465-.065h13.47c.158 0 .312.039.465.065l-7.2 5.4z" />
                  <path d="M4 18h16c1.104 0 2-.896 2-2v-8c0-.027-.006-.051-.008-.077l-8.543 6.4a1 1 0 01-1.898 0l-8.543-6.4c-.002.026-.008.05-.008.077v8c0 1.104.896 2 2 2z" />
                </svg>
                <span> <a href="mailto:contact@adpme.bj" className="text-green-600 hover:underline ml-1">contact@adpme.bj</a></span>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
}

export default ProjectSection;
