const projectsData = [
    {
      title: "PAEB (Projet d'Appui à l'Entrepreneuriat au Bénin)",
      description: "Le PAEB vise à renforcer l’offre de services d’accompagnement pour les MPME et à améliorer l'environnement institutionnel pour un entrepreneuriat de croissance. ",
      objectives: "Le PAEB a pour objectif de contribuer à la rationalisation et au renforcement qualitatif de l’offre de services d’accompagnement technique et financier des MPME ainsi qu’à la création d’un environnement institutionnel plus propice au développement d’un entrepreneuriat de croissance et générateur de valeur ajoutée locale.",
      results: [
        "Identification et sélection définitive de 08 PME et mise en œuvre effective de leur plan d’accompagnement par 02 (SAE) recrutées ;",
        "Recrutement de 12 SAE pour l’appui et l’accompagnement des MPME ;",
        "Sélection de 09 projets stratégiques en droite ligne avec le PAG (soutien de l’Etat par prise de participation à travers la CDC Bénin) et présentant des atouts tels que : création d’emplois, forte valeur ajoutée en termes de créativité et d’innovation et octroi de marchés par l’Etat béninois, dans le but de garantir leur décollage ;",
        "Pré-identification par Appel à Candidature de 129 MPME réparties sur les 12 SAE (instruction de dossiers en cours pour leur passage en comité de sélection et déploiement des plans d’accompagnement) ;",
        "Recrutement de 08 partenaires financiers (banques) avec obtention de proposition de cofinancement."
      ],
      areas: "Bénin",
      funding: "Union européenne, AFD avec une assistance technique d’Expertise France"
    },
    {
      title: "BeniBiz (Projet Business Bénin)",
      description: "Permettre aux entrepreneurs, en particulier les femmes et les jeunes, d’être économiquement prospères. Il est mis en œuvre par Technoserve.",
      objectives: [
        "Accès aux compétences : former et accompagner les entrepreneurs dans les pratiques managériales et commerciales ;",
        "Accès au financement : préparer les entrepreneurs et les mettre en relation avec des sources de financement ;",
        "Accès au marché : préparer les entrepreneurs à accéder à de gros contrats et les connecter avec des clients potentiels ;",
        "Accès à l'information : sensibiliser aux informations clés pour la croissance."
      ],
      results: [],
      areas: "12 départements du Bénin avec 100% des communes du Nord Bénin",
      funding: "Union européenne, Ambassade des Pays-Bas, Coopération Suisse"
    },
    {
      title: "PAEFA (Programme d'Appui à l'Entrepreneuriat Féminin Artisanal au Bénin)",
      description: "Promotion des MPME artisanales féminines pour un développement durable et inclusif. Il est mis en œuvre par l’Association pour la Promotion de l'Education et de la Formation à l'Etranger (APEFE)",
      objectives: [
        "Soutien aux entreprises féminines actives dans des secteurs porteurs.",
        "Formalisation et utilisation d'outils de gestion.",
        "Utilisation des outils numériques pour la promotion des produits."
      ],
      results: [
        "1100 MPME bénéficiaires avec un suivi-conseil ;",
        "42% des MPME formalisées ;",
        "49,91% utilisent les outils de gestion d’entreprises apprises sur 30%  prévu ;",
        "36% des MPME utilisent les outils numériques (WhatsApp, tic Tok, Facebook, Instagram) pour la promotion de leurs produits sur 20% prévue ;",
        "dix-neuf (19) capsules vidéos sur 34 prévues ont été réalisées et ont abordées les thématiques telles que développer les compétences douces, asseoir l’activité sur un plan d’affaires, formaliser l’entreprise dans un cadre juridique adapté, utiliser les outils de gestion financière pour financer l’entreprise, gérer les approvisionnements en fournitures et les stocks de produits finis, gérer les ressources humaines et les relations de travail ;",
      ],
      areas: "Alibori, Atacora, Borgou, Mono",
      funding: "Coopération Belge"
    },
    {
      title: "FP2E (Projet de Formation Professionnelle et Entrepreneuriat pour L’Emploi)",
      description: "Financé par la Banque Mondiale et mis en œuvre par l’Agence de Développement de Sèmè City, ce projet favorise l'entrepreneuriat et l'emploi. Il est mis en œuvre par l’Agence de Développement de Sèmè City. C’est la composante 3 “promotion de l'entrepreneuriat” du projet qui est consacré à l’écosystème d’appui au MPME et à l’entrepreneuriat. En octobre 2024, trois principaux résultats ont été atteint pour un coût global de 60 millions de dollars USD.",
      objectives: [
        "Amélioration de l'environnement des affaires pour les startups et MPME.",
        "Renforcement des capacités entrepreneuriales.",
        "Amélioration de l'accès au financement."
      ],
      results: [
        "4 réformes pour améliorer l'environnement des affaires pour les startups/MPME ;",
        "Renforcement de 20 structures d’appui et formation de 2200 entrepreneurs ;",
        "700 entrepreneurs financés, 200 nouvelles entreprises créées et un fonds de démarrage est mis en place."
      ],
      areas: "Bénin",
      funding: "Banque Mondiale"
    },
    {
      title: "ProPME (Promotion des Petites et Moyennes Entreprises au Bénin)",
      description: "Ce projet soutient la croissance des MPME pour la création d'emplois, mis en œuvre par la GiZ.",
      objectives: "Le projet a pour objectif de soutenir la croissance efficace pour la création d'emplois des micros, petites et moyennes entreprises (MPME).",
      results: [
        "525 MPME accompagnées ;",
        "600 employés recrutés dont 40 % de femmes ;",
        "275 sur 375 employé (e)s promu(e)s, dont 120 femmes, attestent qu’elles ont bénéficié de conditions de travail améliorées ;",
        "Une croissance moyenne du chiffre d’affaires de 20% a été notée au niveau des 1000 MPME ayant participé avec succès aux offres de formation et de coaching (dont 30 % dirigées par des femmes) ;",
        "05 organisations du secteur privé sont renforcées et contribuent au processus d’amélioration des politiques de promotion des MPME."
      ],
      areas: "Il couvre six (06) départements du Bénin (Atlantique, Littoral, Mono, Ouémé, Atacora et Donga) et intervient dans les secteurs de la transformation des produits agroalimentaires, l’artisanat, les services, le tourisme et les énergies renouvelables.",
      funding: "Coopération Allemande"
    },
    {
      title: "IYBA-SEED (Investing in Young Businesses in Africa - Supporting Entrepreneurship Ecosystem Development)",
      description: "Projet pour améliorer l'accès des entreprises en amorçage aux services financiers et non financiers, encourager une culture entrepreneuriale inclusive. Il est mis en œuvre par la SNV et Expertise France.",
      objectives: [
        "Amélioration de l'accès des entreprises en (pré) amorçage aux services financiers et non financiers.",
        "Contribution aux politiques pour un environnement favorable.",
        "Promotion d'une culture entrepreneuriale inclusive pour les femmes et les jeunes."
      ],
      results: [],
      areas: "Bénin (secteurs : maraîchage, agroalimentaire, textile et l’habillement, maintenance automobile, production potière dans la commune de Houyéogbe, transport fluvial dans les communes de Sô-Ava et Aguégués.)",
      funding: "Union européenne, Agence Française de Développement"
    },
    {
      title: "SBN (GAIN) CASCADE (Sun Business Network)",
      description: "Le projet SBN améliore l'accès et la consommation d'aliments sains dans les ménages, notamment pour les femmes et les enfants. Il est mis en œuvre par CARE.",
      objectives: [
        "Amélioration de l'accès et la consommation d'aliments sains.",
        "Renforcement de la résilience des ménages face aux chocs climatiques et économiques.",
        "Amélioration des pratiques commerciales en matière de nutrition."
      ],
      results: [],
      areas: "Communes : Karimama, Malanville, Banikoara, Gogounou, Kalalé, Nikki, Pérérè, Tanguiéta, Matéri, Toucountouna, Boukoumbe, Djidja, Covè, Abomey, Klouékanmè, Toviklin, Djakotomey, Adjohoun, Bonou et Dangbo.",
      funding: "Ambassade des Pays-Bas"
    },
    {
      title: "ACMA 3 (Programme Approche communale pour le marché agricole. Phase 3)",
      description: "Programme pour l’accroissement des revenus et la création d'emplois dans le secteur agricole. Il est mis en œuvre par un consortium composé de International Fertilizer Development Center (IFDC), CARE Bénin/Togo et KIT.",
      objectives: "Le programme ACMA 3 a pour objectif de faciliter un changement systémique visant durablement à l’accroissement des revenus et la création d’emplois soutenu par l’émergence d’un secteur privé agricole et agro-alimentaire.",
      results: [],
      areas: "18 communes des départements des Collines, du Borgou et de la Donga.",
      funding: ""
    },
    {
      title: "IFEV (Investir dans l'Entrepreneuriat Féminin pour une Économie plus Verte au Bénin)",
      description: "Améliorer la participation économique des femmes entrepreneures dans une économie plus verte au Bénin. Il est mis en œuvre par Africa Enterprise Challenge Fund (AECF).",
      objectives: "Le projet IFEV poursuit l’objectif d’amélioration de la participation et la résilience économique des femmes entrepreneures dans une économie plus verte au Bénin et intervient dans l’entrepreneuriat féminin.",
      results: [
        "Amélioration de la performance, de la résilience climatique et de la durabilité des micro, petites et moyennes entreprises appartenant à des femmes dans une économie plus verte ;",
        "Amélioration de l’environnement politique, juridique et réglementaire pour la participation effective des femmes entrepreneures à une économie plus verte."
      ],
      areas: "Bénin",
      funding: "Gouvernement Canadien"
    }
  ];
  
  export default projectsData;
  