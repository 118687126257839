import React, { useState, useEffect, useRef } from 'react';
import fond from '../Images/fond.png'
import { GuidesListe } from '../../../NoSQL';
import axios from 'axios'
import { Modal } from 'flowbite-react'
import GuideCard from '../../../default_components/GuideCard/GuideCard';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const urlstandart = process.env.REACT_APP_URL_STANDART

function LesGuides(props) {
    const [guides, setGuides] = useState([])
    const sectionRef = useRef(null);
    useEffect(() => {
        const newurl = process.env.REACT_APP_URL_STANDART + "api/guides"
        axios.get(newurl, { withCredentials: true })
            .then(response => {
                setGuides(response.data.data)
            })
            .catch(err => {
                alert('Erreur lors de la récupération des informations')
            })
    }, [])

    useEffect(() => {
        // Animation des cartes au scroll
        gsap.fromTo(
          sectionRef.current.querySelectorAll('.card'), // Sélection des éléments des cartes dans la section
          { opacity: 0, y: 50 },
          {
            opacity: 1,
            y: 0,
            stagger: 0.2,
            duration: 2,
            ease: "power2.out",
            scrollTrigger: {
              trigger: sectionRef.current, // Déclenche l'animation lorsque le composant est visible
              start: "top 80%",  // Animation déclenchée lorsque le haut du composant atteint 80% de la hauteur de la fenêtre
              end: "bottom 20%", // Animation terminée lorsque le bas du composant atteint 20% de la hauteur de la fenêtre
              once: false,        // L'animation ne se déclenche qu'une seule fois
            },
          }
        );
      }, [guides]);

    return (
        <div className='max-xl:px-4 px-14 mx-auto max-w-screen-xl text-left py-24 lg:py-15 sm:py-15' ref={sectionRef}>
            <div className="lg:grid lg:grid-cols-3 lg:gap-4 sm:flex sm:flex-col max-xl:space-y-2">
                {guides.length <= 0 ? (<span>Aucun guide pour le moment</span>) :
                    guides.map((guide,index) => {
                        let imginfo = guide.icon
                        let tabimg = []
                        tabimg = imginfo.split(',')
                        imginfo = tabimg[0]
                        imginfo = imginfo.replace(/\\/g, "/")

                        let resinfo = guide.ressource
                        let tabres = []
                        tabres = resinfo.split(',')
                        resinfo = tabres[0]
                        resinfo = resinfo.replace(/\\/g, "/")
                        return (
                            <div key={index} className="card">
                                <GuideCard id={guide.id} title={guide.title} doc={resinfo} img={`${urlstandart}api/${imginfo}`} />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default LesGuides;