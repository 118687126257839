import React from 'react';
import LesProgrammes from './Components/LesProgrammes';
import Banniere from '../../default_pages/banniere/Banniere';
import projectsData from './Components/ProjectsData';

function Programme(props) {
    return (
        <>
            <Banniere desc1="Les Projets / Programmes" desc2="des Partenaires Techniques et Financiers" />
            <LesProgrammes projects={projectsData} />
        </>
    );
}

export default Programme;