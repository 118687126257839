import React, { useEffect } from 'react';
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
} from "@material-tailwind/react";
import { Link, useParams, useNavigate } from 'react-router-dom';
import Eservices from './Eservices';
import LesServicesFinancier from './LesServicesFinancier';
import LesServicesNonFinancier from './LesServicesNonFinancier';
import PMEForm from './PMEForm';
import PMESearch from './PMESearch';

function TabsComponentsService(props) {
    const { id } = useParams()
    const [activeTab, setActiveTab] = React.useState(id || "eservices");
    const nav = useNavigate()
    

    const handleTabClick = (value) => {
        setActiveTab(value);

        nav(`./${value}`);
    };

    useEffect(() => {
        
        nav(`/service`);
    }, [id]);

    const data = [
        {
            label: "E-SERVICES",
            value: "eservices",
            desc: <Eservices />,
        },
        {
            label: "AUTRES SERVICES",
            value: "autreservices",
            desc: <><LesServicesNonFinancier /><LesServicesFinancier /></>,
        },
        {
            label: "FORMULAIRE DES PME",
            value: "formpme",
            desc: <PMEForm />,
        },
        {
            label: "AJOUT D'UN ASSOCIÉ",
            value: "projet",
            desc: <PMESearch />,
        },
    ];
    return (
        <>
            <div className="border-b border-blue-gray-50 font-[' Montserrat']">
                <Tabs value={activeTab}>
                    <TabsHeader
                        className="rounded-none bg-white w-full lg:pr-60 lg:pl-32"
                        indicatorProps={{
                            className:
                                "bg-transparent border-b-2 border-yellow-300 shadow-none rounded-none p-7 w-full",
                        }}
                    >
                        {data.map(({ label, value }) => (
                            <Tab
                                key={value}
                                value={value}
                                onClick={() => handleTabClick(value)}
                                className={activeTab === value ? "text-gray-900 font-semibold p-7 w-[24%] max-md:w-[25%] font-[' Montserrat']" : "font-[' Montserrat'] p-7 w-[24%] max-md:w-[25%] font-semibold"}
                            >
                                <span className='max-md:text-[12px]'>{label}</span>
                            </Tab>
                        ))}
                    </TabsHeader>
                    <TabsBody className='lg:pr-72 lg:pl-32'>
                        {data.map(({ value, desc }) => (
                            <TabPanel key={value} value={value}>
                                {desc}
                            </TabPanel>
                        ))}
                    </TabsBody>
                </Tabs>
            </div>
        </>
    );
}

export default TabsComponentsService;