import React, {useState, useEffect, useRef} from 'react';
import { RecrutementsListe } from '../../../NoSQL';
import { Dropdown } from 'flowbite-react';
import OpportuniteCard from '../../../default_components/OpportuniteCard/OpportuniteCard';
import axios from 'axios'
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { TextPlugin } from 'gsap/TextPlugin';

gsap.registerPlugin(ScrollTrigger, TextPlugin);

const urlstandart = process.env.REACT_APP_URL_STANDART

function Offre(props) {
    const [offres, setOffres] = useState([])
    const [criteres, setCriteres] = useState(['Diplôme en informatique','Compétences techniques en SE','Expériences: 10 ans ou plus','Capacités de diagnostic','Grande capacité d\'analyse','Capacité à d\'adapter'])
    const titleRef = useRef(null);
    const sectionRef = useRef(null);

    useEffect(() => {
        if (!titleRef.current) return; // Vérifie que les références sont définies

        // Animation des textes
        const tlText = gsap.timeline({
            repeat: -1, // Répétition infinie
            yoyo: true, // Animation dans les deux sens
            defaults: { ease: "none" }
        });

        tlText.to(titleRef.current, {
            duration: 2,
            text: "Rejoigner l'équipe ADPME",
            delay: 1,
            ease: "power2.out",
        });

    }, []);

    useEffect(() => {
        const newurl = process.env.REACT_APP_URL_STANDART + "api/recrutements"
        axios.get(newurl, { withCredentials: true })
            .then(response => {
                const offres = response.data.data.recrutements
                const filter = offres.filter((el) => {
                    return el.typeDeRecru === "PERSON"
                })
                setOffres(filter)
                console.log(response.data.data)
            })
            .catch(err => {
                alert('Erreur lors de la récupération des informations')
            })
    }, [])

    useEffect(() => {
        // Animation des cartes au scroll
        gsap.fromTo(
          sectionRef.current.querySelectorAll('.card'), // Sélection des éléments des cartes dans la section
          { opacity: 0, y: 50 },
          {
            opacity: 1,
            y: 0,
            stagger: 0.2,
            duration: 2,
            ease: "power2.out",
            scrollTrigger: {
              trigger: sectionRef.current, // Déclenche l'animation lorsque le composant est visible
              start: "top 80%",  // Animation déclenchée lorsque le haut du composant atteint 80% de la hauteur de la fenêtre
              end: "bottom 20%", // Animation terminée lorsque le bas du composant atteint 20% de la hauteur de la fenêtre
              once: false,        // L'animation ne se déclenche qu'une seule fois
            },
          }
        );
      }, [offres]);

    return (
        <>
            <div ref={sectionRef} className="lg:py-10 font-['Montserrat']">
                <div className="grid grid-cols-1 pb-16 max-md:pb-16 max-md:flex max-md:flex-col">
                    <div className="font-bold max-md:text-xl text-4xl text-neutral-900" ref={titleRef}>
                        Consultez nos avis de recrutement
                    </div>
                    {/* <div className='grid justify-items-end'>
                        <Dropdown label="Dropdown bottom" placement="bottom">
                            <Dropdown.Item>Dashboard</Dropdown.Item>
                            <Dropdown.Item>Settings</Dropdown.Item>
                            <Dropdown.Item>Earnings</Dropdown.Item>
                            <Dropdown.Item>Sign out</Dropdown.Item>
                        </Dropdown>
                    </div> */}
                </div>
                <div className="max-md:flex max-md:flex-col grid grid-cols-3 gap-4 card">
                    {/* {offres.length <= 0 ? (<span>Aucun Offre pour le moment</span>) :
                        offres.map((re) => (
                            <div>
                                <div className="max-xl:w-full h-full max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                    <a href={`/details-recrutement/${re.poste}`}>
                                        <h5 className="mb-2 text-2sm font-bold tracking-tight text-gray-900 dark:text-white">{re.poste}</h5>
                                    </a>
                                    <p className="mb-3 text-sm font-normal text-gray-700 dark:text-gray-400">{re.subtitle}</p>
                                    <a href={`/details-recrutement/${re.poste}`} className="rounded-l-lg rounded-tr-lg inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-amber-700 rounded-lg hover:bg-amber-800 focus:ring-4 focus:outline-none focus:ring-amber-300 dark:bg-amber-600 dark:hover:bg-amber-700 dark:focus:ring-amber-800">
                                        JE SUIS INTERESSE(E)
                                        <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        ))
                    } */}
                    {offres.map((el)=>{
                        let resinfo = el.document
                        let tabres = []
                        tabres = resinfo.split(',')
                        resinfo = tabres[0]
                        resinfo = resinfo.replace(/\\/g, "/")

                        const df = new Date(el.endDate)
                        const enddate = df.toISOString().split('T')[0]
                        return(
                            <>
                                <OpportuniteCard titre={el.poste} date={enddate} subtitle={el.subtitle} lien={`/details-recrutement/${el.poste}`} document={`${urlstandart}api/${resinfo}`} />
                            </>
                        )
                    })}
                    
                </div>
            </div>
            {/* <div className='max-xl:px-4 px-14 mx-auto max-w-screen-xl text-left py-24 lg:py-15 sm:py-15'>
                <div className='mb-10'>
                    <span className='text-4xl font-bold'>Nos offres d'emploi</span>
                </div>
                
            </div> */}
        </>
    );
}

export default Offre;